// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";

interface StoreList {
  id: string;
  attributes: {
    image: string;
    store_name: string;
    description: string;
  }
}

interface INavigateDetailsPageTo {
  id: number | undefined;
  screenName: string;
  raiseMessage?: Message;
}
// Customizable Area End


export const configJSON = require("../../src/config.js");


export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  isAlert: boolean;
  alertType: 'success' | 'error' | 'warning' | 'info';
  alertMsg: string;
  circularProgress: boolean;
  storeList: StoreList[],
  token: string,
  page: number,
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BuyerStoreController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchBuyerStoreCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      isAlert: false,
      alertType: 'success',
      alertMsg: "",
      circularProgress: false,
      storeList: [],
      token: "",
      page: 1,
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.fetchBuyerStoreCallID) {
      if (responseJson.errors) {
        this.setState({
          // error: "An error occurred, please try again",
          isAlert: true,
          alertType: "error",
          alertMsg: responseJson.errors.length > 0 ? responseJson.errors[0].token : responseJson.errors ,
          circularProgress: false
        });
      }else{
        const arrTrendList = [...this.state.storeList, ...responseJson?.data?.data]
        this.setState(({
          storeList: [...arrTrendList],
            page: this.state.page +1,
            circularProgress: false,
        }))
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    const authToken  = await getStorageData('auth-token');
    this.setState({
      token: authToken,
    });
    this.getbuyerStores();
    const dataBox = document.getElementById('scrollableDiv') as HTMLElement;
    dataBox.addEventListener('scroll', this.handleScroll);
    // Customizable Area End
  }
  navigateToDeatils = ({id,screenName,raiseMessage}: INavigateDetailsPageTo) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    id && 
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    raiseMessage &&
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage(message.id, message);
};
  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };

  handleScroll = async () => {
    const dataBox = await document.getElementById('scrollableDiv') as HTMLElement;
    if(dataBox.scrollHeight - dataBox.scrollTop === dataBox.clientHeight){
        this.getbuyerStores();
    };
};

  getbuyerStores = async () => {
    this.setState({
      circularProgress: true
    })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchBuyerStoreCallID = requestMessage.messageId;
    
    const apiRoute =  `${configJSON.getStoreBuyerEndPoint}?page=${this.state.page}&per_page=20`

    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiRoute
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
