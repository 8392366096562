export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logout = require("../assets/logout.svg");
export const loyality = require("../assets/loyalityPoint.svg");
export const myOrder = require("../assets/myOrder.svg");
export const sidebarimg = require("../assets/sidebarimg.svg");
export const myProfile = require("../assets/myProfile.svg");
export const paymentHistory = require("../assets/paymentHistory.svg");
export const reviews = require("../assets/reviews.svg");
export const saveCard = require("../assets/saveCard.svg");
export const setting = require("../assets/setting.svg");
export const stylist = require("../assets/stylist.svg");
export const wishlist = require("../assets/wishlist.svg");
export const view = require("../assets/view_.svg");
export const Vector = require("../assets/Vector.svg");
export const scroll = require("../assets/scroll.svg");
export const girl = require("../assets/girl.svg");
export const close = require("../assets/close.svg");
export const girl1 = require("../assets/girl1.svg");
export const boy = require("../assets/boy.svg");
export const girl2 = require("../assets/girl2.svg");
export const address = require("../assets/address.svg");
export const plus = require("../assets/plus.svg");
export const threeDot = require("../assets/threeDot.svg");
export const button_ = require("../assets/button_.svg");
export const image_mastercard = require("../assets/image_mastercard.svg");
export const profileImage = require("../assets/profileImage.png");
export const upload = require("../assets/uploadJpeg.svg");
export const usericon= require("../assets/user.png");
export const policy= require("../assets/policy.png");
export const faqicon= require("../assets/faq.png");
export const bank= require("../assets/bank.png");
export const admin= require("../assets/admin.png");
export const calendar= require("../assets/calendar.svg");
export const message = require("../assets/message.svg")
export const search = require("../assets/search.svg")
export const whiteMessage = require("../assets/whiteMessage.svg")
export const Noimageavailable = require("../assets/Noimageavailable.png")
export const uploadIcon = require("../assets/uploadIcon.svg")








