import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { Grid, Typography,IconButton,Menu,InputBase, styled, Box, List, ListItem, ListItemText } from "@material-ui/core";
import InfiniteScroll from 'react-infinite-scroll-component';
import ClientChatController, {
  Props,
} from "./ClientChatController";
import { ThemeProvider } from "react-native-elements";
import StylishSidebar from "../../../components/src/Stylish/StylishSidebar";
import StylishHeader from "../../../components/src/Stylish/StylishHeader";
import { search } from "../../customisableuserprofiles2/src/assets";
import Loader from "../../../components/src/LoaderContainer";
import OneToOneChat from "./OneToOneChat.web";
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
export const clients = [
  { name: 'Scarlet Johansson', message: 'I can process the order within 24 hours. I’ll send you the request. Please accept.' },
  { name: 'Eleanor Pena', message: 'Neque porro quisquam est, qui dolorem ipsum qui...' },
  { name: 'Jane Cooper', message: 'Sed ut perspiciatis unde omnis iste natus error sit v...' },
  { name: 'Albert Flores', message: 'Duis aute irure dolor in reprehenderit in voluptate...' },
  { name: 'Cody Fisher', message: 'Ut enim ad minima veniam, quis nostrum exercitati...' },
  { name: 'Jerome Bell', message: 'Lorem ipsum dolor sit amet, consectetur adipiscin...' },
  { name: 'Kathryn Murphy', message: 'Ut enim ad minima veniam, quis nostrum exercitati...' },
  { name: 'Kathryn Murphy2', message: 'Ut enim ad minima veniam, quis nostrum exercitati2...' },
  { name: 'Cody Fisher', message: 'Ut enim ad minima veniam, quis nostrum exercitati...' },
  { name: 'Jerome Bell', message: 'Lorem ipsum dolor sit amet, consectetur adipiscin...' },
  { name: 'Kathryn Murphy', message: 'Ut enim ad minima veniam, quis nostrum exercitati...' },
  { name: 'Kathryn Murphy2', message: 'Ut enim ad minima veniam, quis nostrum exercitati2...' },
];
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class ClientChat extends ClientChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid
            item
            xl={2}
            md={2}
            id="stylish-my-clients-sidebar-catalogue"
            lg={2}
            sm={2}
            xs={2}
          >
            <StylishSidebar navigation={this.props.navigation} />
          </Grid>
          <Grid
            item
            xl={10}
            md={10}
            id="stylish-my-clients-header-catalogue"
            lg={10}
            sm={10}
            xs={10}
          >
            <StylishHeader navigation={this.props.navigation} />
            <ClientChatContainer>
              <ClientChatTitleContainer>
                <Typography className="main-title">Clients</Typography>
              </ClientChatTitleContainer>
              <ClientsChatContainer>
                <Box
                  className="client-box"
                >
                  Chats
                </Box>
                <Box data-test-id="navigateToClient" onClick={this.navigateToClient} className="chat-box">Clients</Box>
              </ClientsChatContainer>
             {this.state.isLoad ? <Box style={{ height: "100px", margin: "24%"}}>
                <Loader
                  id="step-2-loader"
                  dataTestId="step-2-loader-test"
                />
              </Box> : 
              <Box display="flex">
                <Grid container spacing={8}>
                  <Grid item xs={12} md={4} >
                      <InputBase
                        value={this.state.query}
                        placeholder="Search Clients"
                        className="searchBox"
                        startAdornment={
                          <IconButton className="searchBtn" aria-label="search">
                            <img src={search} width="20px" height="20px" />
                          </IconButton>
                        }
                        onChange={(event: React.ChangeEvent<HTMLInputElement>)=> this.debounceClientChatSearch(event)}
                        name="searchValue"
                      />
                    { this.state.clientList && this.state.clientList?.length>0 ? <Box className="ChatContainerBox">
                      <InfiniteScroll
                        dataLength={this.state.clientList.length}
                        next={this.getClientsList}
                        hasMore={this.state.hasmoreClients}
                        loader={
                          <h4>Loading...</h4>
                        }
                        scrollableTarget="scrollableDiv"
                        
                      >
                      <List>
                        {this.state.clientList.map((client, index) => (
                          <ListItem
                            button
                            key={index}
                            data-test-id="handleClientClick"
                            onClick={() => this.handleClientClick(client)}
                            selected={this.state?.selectedClient?.id === client.id}
                          >
                            <ListItemText
                              primary={client.attributes.candidate_name}
                              secondary={client.attributes.last_message_type}
                            />
                          </ListItem>
                        ))}
                      </List>
                      </InfiniteScroll>
                    </Box>: <Box style={{ display: 'flex', color: '#94A3B8', alignItems: 'center', justifyContent: 'center', fontFamily: "Lato, sans-serif" }}>No clients found</Box>}
                  </Grid>
                  <Grid item xs={12} md={8}>
                   <OneToOneChat
                      navigation={this.props.navigation}
                      chat_id={this.state.selectedClient?.id}
                      receiverDetails={this.state.selectedClient}
                      senderDetails={{
                        profile: this.state.stylistProfile,
                        full_name: this.state.stylistFullName,
                      }}
                   />
                  </Grid>
                </Grid>
              </Box>
              }
            </ClientChatContainer>
          </Grid>
          <Snackbar
              open={this.state.isAlert}
              autoHideDuration={3000}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              onClose={this.oncloseMsgAlert}
              data-test-id="alertmessageId"
          >
            <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
          </Snackbar>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const ClientChatContainer = styled(Box)({
  padding: "36px 30px",
  minHeight: "calc(100vh - 85px)",
  "& .ChatContainerBox": {
    height: "676px", overflowY: "auto"
  },
  "& .chatMessageContainerMain": {
    height: "732px",
    boxShadow: "0px 4px 8px 0px #00000008",
  },
  "& .messageTimeText": {
    fontSize: "12px",
    fontWeight: 400,
    color: "#8A91A8",
    fontFamily: "lato"
  },
  "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
    borderBottom: "1px solid #CBD5E1",
    background: "#F1F5F9",
  },
  "& .searchBtn": {
    padding: "10px"
  },
  "& .searchBox": {
    border: "1px solid #CBD5E1",
    fontWeight: 400,
    background: "#FFFFFF",
    fontSize: "18px",
    padding: "10px, 8px, 10px, 12px",
    height: "56px",
    borderRadius: "2px",
    marginBottom: "20px",
    fontFamily: "lato",
    width: "100%",
  },
  "& .MuiInputBase-root":{
    backgroundColor: "#F8FAFC",
    fontSize: "18px",
    color: "#375280",
    fontFamily: "lato",
    fontWeight: 400
  },
  "& .MuiListItemText-primary": {
      fontSize: "18px",
      fontFamily: "lato",
      color: "#375280",
      fontWeight: 700
  },
  "& .MuiListItemText-secondary":{
      fontSize: "16px",
      fontFamily: "lato",
      color: "#94A3B8",
      fontWeight: 400,
  },
  "& .MuiOutlinedInput-root": {
    height: "60px",
    borderRadius: "2px",
    border: "1px solid #F1F5F9",
    '& fieldset': {
      border: 'none'
    },
  },
  "& .MuiListItem-gutters":{
    borderBottom: "1px solid #CBD5E1"
  },
});
const ClientChatTitleContainer = styled(Box)({
  paddingBottom: 24,
  justifyContent: "flex-start",
  display: "flex",
  alignItems: "center",
  gap: 20,
  "& .main-title": {
    color: "#375280",
    fontWeight: 700,
    fontFamily: "Lato, sans-serif",
    fontSize: 24,
    width: "100%",
  },
});
const ClientsChatContainer = styled(Box)({
  display: "flex",
  width: "100%",
  gap: 24,
  height: 64,
  marginBottom: 48,
  "& .client-box": {
    backgroundColor: "#375280",
    padding: "11px 20px 11px 20px",
    borderRadius: 2,
    color: "#FFF",
    justifyContent: "center",
    fontSize: 18,
    display: "flex",
    fontFamily: "Lato, sans-serif",
    alignItems: "center",
    fontWeight: 700,
    width: "50%",
  },
  "& .chat-box": {
    padding: "11px 20px 11px 20px",
    borderRadius: 2,
    color: "#E2E8F0",
    fontWeight: 700,
    backgroundColor: "#FFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Lato, sans-serif",
    border: "1px solid #E2E8F0",
    width: "50%",
    fontSize: 18,
  },
});
// Customizable Area End
