import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Attributes } from "./ClientChatController";

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  chat_id: number | string | undefined;
  senderDetails: {
    profile: string;
    full_name: string;
  };
  receiverDetails: Attributes | null;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  anchorElClientsChat: HTMLElement | null;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OneToOneChatController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getClientsAPICallId: string = ""
  getStylistInfoAPICallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      anchorElClientsChat: null,
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start
  navigateToNewPaymentReq = (component: string) => {
    this.props.navigation.navigate(component, {id: this.props.chat_id} )
  }

  handleClickChatMore = (event: React.MouseEvent<HTMLImageElement>) => {
    this.setState({ anchorElClientsChat: event.currentTarget });
  };

  handleCloseChatMore = () => {
    this.setState({ anchorElClientsChat: null });
  };

  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
