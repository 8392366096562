import React from "react";
// Customizable Area Start
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { TextField, Chip, Button, styled, Grid, Breadcrumbs, IconButton, Typography, Box, Snackbar } from "@material-ui/core";
import SourceProductController, {
  Props,
} from "./SourceProductController.web";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import ClearIcon from '@material-ui/icons/Clear';
import { uploadIcon } from "./assets";
import InputAdornment from '@material-ui/core/InputAdornment';
import Alert from '@material-ui/lab/Alert';
// Customizable Area End
export default class SourceProduct extends SourceProductController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  topPart = () => {
    return (<BreadcrumbStyle>
      <Breadcrumbs aria-label="breadcrumb" className="breadcrumb" separator={<KeyboardArrowRightRoundedIcon />}>
        <Typography color="inherit">Stylist </Typography>
        <Typography color="inherit">Source Product </Typography>
      </Breadcrumbs>
      <Box className="pageTitle">

        <IconButton className="iconButton" data-testid="iconButton" onClick={() => this.handleNavigation("HiredStylist")}>
          <KeyboardArrowLeftRoundedIcon />
        </IconButton>
        <Typography className="text">Source Product </Typography>
      </Box>
    </BreadcrumbStyle>)
  }

  // Customizable Area End
  render() {
    // Customizable Area Start 

    const initialValues = {
      productName: '',
      productDescription: '',
      productColor: '',
      productSizes: '',
      minPrice: '',
      maxPrice: '',
      attachment: null,
    };

    const validationSchema = Yup.object({
      productName: Yup.string().required('Product name is required'),
      productDescription: Yup.string().required('Product description is required'),
      productColor: Yup.string().required('Product color is required'),
      productSizes: Yup.string().required('Product size is required'),
      minPrice: Yup.number().required('Minimum price is required').positive().integer(),
      maxPrice: Yup.number().required('Maximum price is required').positive().integer(),
    });

    return (
      <>
        <Header navigation={this.props.navigation} />
        <Snackbar
          open={this.state.isAlert}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.oncloseAlert}
        ><Alert variant="filled" severity={this.state.severity}>{this.state.message}</Alert></Snackbar>
        <Grid container style={{ maxWidth: "1440px", margin: "40px auto", width: "100%" }}>
          <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
            {this.topPart()}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={this.favStylistProfile}
            >
              {({ setFieldValue, errors, touched, getFieldProps }) => (
                <ClientNewOrderRequestContainer>
                  <Grid container spacing={3} className="requestFormContainer">

                    <Grid item xs={12}>
                      <Typography className="inputNameTxnOrder">Product Name</Typography>
                      <ClientNewRequestCustomInput
                        {...getFieldProps("productName")}
                        id="product-name"
                        data-testid="product-name"
                        placeholder="Product name here"
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        error={Boolean(errors.productName && touched.productName)}
                      />
                      {errors.productName && touched.productName && (
                        <Typography className="errorText">{errors.productName}</Typography>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Typography className="inputNameTxnOrder">Product Description</Typography>
                      <ClientNewRequestCustomInput
                        {...getFieldProps("productDescription")}
                        id="product-description"
                        placeholder="Product description here"
                        variant="standard"
                        multiline
                        minRows={4}
                        InputProps={{ disableUnderline: true }}
                        error={Boolean(errors.productDescription && touched.productDescription)}
                      />
                      {errors.productDescription && touched.productDescription && (
                        <Typography className="errorText">{errors.productDescription}</Typography>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography className="inputNameTxnOrder">Product Colour</Typography>
                      <ClientNewRequestCustomInput
                        {...getFieldProps("productColor")}
                        id="product-color"
                        placeholder="Product Colour"
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        error={Boolean(errors.productColor && touched.productColor)}
                      />
                      {errors.productColor && touched.productColor && (
                        <Typography className="errorText">{errors.productColor}</Typography>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography className="inputNameTxnOrder">Product Sizes</Typography>
                      <ClientNewRequestCustomInput
                        {...getFieldProps("productSizes")}
                        id="product-sizes"
                        placeholder="Product Sizes"
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        error={Boolean(errors.productSizes && touched.productSizes)}
                      />
                      {errors.productSizes && touched.productSizes && (
                        <Typography className="errorText">{errors.productSizes}</Typography>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography className="inputNameTxnOrder">Minimum Price</Typography>
                      <ClientNewRequestCustomInput
                        {...getFieldProps("minPrice")}
                        id="min-price"
                        placeholder="Amount"
                        type="number"
                        variant="standard"
                        error={Boolean(errors.minPrice && touched.minPrice)}
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: <InputAdornment position="start" className="customAdornment">$</InputAdornment>,
                        }}
                      />
                      {errors.minPrice && touched.minPrice && (
                        <Typography className="errorText">{errors.minPrice}</Typography>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography className="inputNameTxnOrder">Maximum Price</Typography>
                      <ClientNewRequestCustomInput
                        {...getFieldProps("maxPrice")}
                        id="max-price"
                        placeholder="Amount"
                        type="number"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: <InputAdornment position="start" className="customAdornment">$</InputAdornment>,
                        }}
                        error={Boolean(errors.maxPrice && touched.maxPrice)}
                      />
                      {errors.maxPrice && touched.maxPrice && (
                        <Typography className="errorText">{errors.maxPrice}</Typography>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Typography className="inputNameTxnOrder">Attachments</Typography>
                      <Box className="uploadAfterContainer">
                        <input
                          id="fileUpload"
                          name="attachment"
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          multiple
                          style={{ display: 'none' }}
                          data-testid="fileUpload"
                          onChange={(e) => this.handleFileChange(e, setFieldValue)}
                        />
                        <label htmlFor="fileUpload">
                          <IconButton component="span">
                            <img src={uploadIcon} className="uploadBeforeUploadIcon" alt="Upload Icon" />
                          </IconButton>
                          <Typography style={{ color: "#47628a", marginTop: "8px" }}>
                            Upload png, jpg, jpeg
                          </Typography>
                        </label>
                      </Box>

                      <Box className="fileListContainer">
                        {this.state.selectedFiles.map((file, index) => (
                          <Chip
                            key={index}
                            data-testid="clearIcon"
                            label={file.name}
                            onDelete={() => this.removeFile(index, setFieldValue)}
                            deleteIcon={<ClearIcon />}
                            style={{ marginTop: '8px', marginRight: '8px' }}
                          />
                        ))}
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box className="inputSendContainer">
                        <Button className="sendBtn" data-testid="sendBtn" type="submit">
                          Submit
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </ClientNewOrderRequestContainer>
              )}
            </Formik>
          </Grid>
        </Grid>
        <Footer navigation={this.props.navigation} />
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const BreadcrumbStyle = styled(Box)({
  margin: "20px",
  "& .pageTitle": {
    alignItems: "center",
    display: "flex",
    margin: "20px 0"
  },
  "& .iconButton": {
    backgroundColor: '#fff',
    padding: '8px',
    marginRight: '8px',
    borderRadius: '50%',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
  },
  "& .text": {
    marginLeft: "5px",
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: 600,
    color: '#375280',
  },
  "& .breadcrumb": {
    lineHeight: '22px',
    textAlign: 'left',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: 400,
    textDecoration: 'none',
    color: '#475569',
  }
})

const ClientNewOrderRequestContainer = styled(Form)({
  "& .errorText": {
    color: "red",
    fontSize: "14px",
    position: "absolute",
  },
  "& .requestFormContainer": {
    marginTop: 20, padding: 20, boxShadow: "0px 2px 8px 0px #00000014",
    width: "968px", margin: "0 auto"
  },
  "& .uploadAfterContainer": {
    width: '100%', height: "130px", 
    borderRadius: "8px",
    padding: "16px",
    textAlign: "center",
    background: "#F8F8F8",
    cursor: "pointer",
  },
  "& .uploadAfterBoxContainer": {
    position: 'relative',
    borderRadius: 2,
    height: "184px",
    padding: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f1f1f1',
  },
  "& .uploadAfterImgContainer": {
    width: '100%', height: '184px'
  },
  "& .uploadAfterReplaceBtn": {
    color: "#3f51b5",
    width: "480px",
    border: "1px solid #CCBEB1",
    borderRadius: "2px",
    height: "54px",
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    fontStyle: "inherit",
    backgroundColor: "#fff",
    position: "absolute",
    bottom: "6%",
    left: "15%",
    textTransform: "none"
  },
  "& .uploadAfterDeleteBtn": {
    position: 'absolute',
    top: 16,
    right: 16,
    backgroundColor: '#ffffff',
    color: '#f44336',
    boxShadow: "0px 2px 8px 0px #00000014"
  },
  "& .uploadBeforeBoxContainer": {
    borderRadius: 2,
    padding: 4,
    height: "184px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f1f1f1',
  },
  "& .uploadBeforeUploadIcon": {
    width: "40px", height: "40px"
  },
  "& .uploadBeforeUploadText": {
    marginTop: 2, fontFamily: "Lato", fontSize: "18px", fontWeight: 700, color: '#375280'
  },
  "& .inputCancelContainer": {
    padding: "10px 0",
    display: "flex",
    justifyContent: "end"
  },
  "& .inputSendContainer": {
    padding: "10px 0",
    display: "flex",
    justifyContent: "center"
  },
  "& .submitBtn": {
    width: "100%",
    height: "53px",
    fontWeight: 800,
    fontSize: "20px",
    textTransform: "none",
    background: "#CCBEB1",
    color: "white",
    borderRadius: "4px"
  },
  "& .cancelBtn": {
    width: "50%",
    height: "64px",
    fontWeight: 500,
    color: "#375280",
    fontSize: "20px",
    textTransform: "none",
    border: "1px solid #CCBEB1",
    borderRadius: "2px"
  },
  "& .sendBtn": {
    width: "50%",
    height: "64px",
    fontWeight: 800,
    fontSize: "20px",
    textTransform: "none",
    background: "#CCBEB1",
    color: "white",
    borderRadius: "2px", 
    fontFamily: 'Lato',
  },
  "& .inputNameTxnOrder": {
    color: "#375280",
    fontFamily: "Lato",
    fontSize: 18,
    fontWeight: 700,
    marginBottom: "10px"
  }
});

const ClientNewRequestCustomInput = styled(TextField)({
  background: "#f1f1f1",
  border: "1px solid #F8F8F8",
  borderRadius: "4px",
  width: "-webkit-fill-available",
  fontStyle: "normal !important",
  fontFamily: "Lato",
  padding: "8px 10px",
  marginBottom: 10,
  position: "relative",
  "& .customAdornment p": {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '18px',
    color: '#375280',
    padding: '4px 0px', 
  },
  "& .MuiInputBase-input": {
    padding: "6px 3px 7px"
  },
  '& ::placeholder': {
    color: "#375280",
    fontSize: "18px",
    lineHeight: "24px",
    opacity: 1,
    fontWeight: 400,
    fontStyle: "normal !important",
    fontFamily: "Lato",
  },
  '& .MuiInputBase-root': {
    color: "#375280",
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 400,
    fontStyle: "normal !important",
    fontFamily: "Lato",
  }
});

// Customizable Area End
