// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Customisableuserprofiles2 from "../../blocks/customisableuserprofiles2/src/Customisableuserprofiles2";
import Wishlist from "../../blocks/customisableuserprofiles2/src/Wishlist.web"
import Creditdebitcardpayments from "../../blocks/creditdebitcardpayments/src/Creditdebitcardpayments";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Productdescription3 from "../../blocks/productdescription3/src/Productdescription3";
import ProductdetailsWeb from "../../blocks/productdescription3/src/Productdetails.web";
import PaymentDetails from "../../blocks/productdescription3/src/PaymentDetails.web";
import SellerReadyForCollectionDetail from "../../blocks/ordermanagement/src/SellerReadyForCollectionDetail.web";
import SellerProductOverview from "../../blocks/analytics/src/SellerProductOverview.web";
import OutForDeliveryDetail from "../../blocks/ordermanagement/src/OutForDeliveryDetail.web";
import ReturnDetail from "../../blocks/ordermanagement/src/ReturnDetail.web";
import SellerDeliveredDetail from "../../blocks/ordermanagement/src/SellerDeliveredDetail.web";
import SplashScreen from "../../blocks/splashscreen/src/SplashScreen.web";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Customform from "../../blocks/customform/src/Customform";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import ProductSearch from "../../blocks/advancedsearch/src/ProductSearch.web";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Applepayintegration2 from "../../blocks/applepayintegration2/src/Applepayintegration2";
import Collecttransactionfees from "../../blocks/collecttransactionfees/src/Collecttransactionfees";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import Admanager from "../../blocks/admanager/src/Admanager";
import Googleadsenseintegration from "../../blocks/googleadsenseintegration/src/Googleadsenseintegration";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Savedcards from "../../blocks/savedcards/src/Savedcards.web";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
// import Notifications from "../../blocks/notifications/src/Notifications";
import Cfchatbot2 from "../../blocks/cfchatbot2/src/Cfchatbot2";
import Storecredits2 from "../../blocks/storecredits2/src/Storecredits2";
import Refundmanagement from "../../blocks/refundmanagement/src/Refundmanagement";
import Inapppurchasing from "../../blocks/inapppurchasing/src/Inapppurchasing";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import ProductDetail from "../../blocks/catalogue/src/ProductDetail.web";
import EditCatalougeWeb from "../../blocks/catalogue/src/EditCatalougeWeb.web"
import Shippingaddressvalidation2 from "../../blocks/shippingaddressvalidation2/src/Shippingaddressvalidation2";
import EditShippingaddressvalidation2 from "../../blocks/shippingaddressvalidation2/src/EditShippingaddressvalidation2.web";
import AddShippingaddressvalidation2 from "../../blocks/shippingaddressvalidation2/src/AddShippingaddressvalidation2.web"
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Contactus from "../../blocks/contactus/src/Contactus";
import PageNotFound from "../../../packages/components/src/PageNotFound";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Applelogin2 from "../../blocks/applelogin2/src/Applelogin2";
import Linkshare from "../../blocks/linkshare/src/Linkshare";
import Addresses from "../../blocks/addressmanagement/src/Addresses";
import AddAddress from "../../blocks/addressmanagement/src/AddAddress";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails.web"
import ReturnStatus from "../../blocks/ordermanagement/src/ReturnStatus.web"
import TrackOrder from "../../blocks/ordermanagement/src/TrackOrder.web"
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Deliveryestimator from "../../blocks/deliveryestimator/src/Deliveryestimator";
import Wishlist2 from "../../blocks/wishlist2/src/Wishlist2";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import MobileAccountLoginBlock from "../../blocks/email-account-login/src/MobileAccountLoginBlock.web";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ResetPassword from "../../blocks/forgot-password/src/ResetPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import ResetOTPPassword from "../../blocks/forgot-password/src/ResetOTPPassword.web"
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import DashboardWeb from "../../blocks/dashboard/src/Dashboard.web";
import Chat from "../../blocks/chat/src/Chat";
import ChatView from "../../blocks/chat/src/ChatView";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Expressdelivery from "../../blocks/expressdelivery/src/Expressdelivery";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail.web";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Inventorymanagement2 from "../../blocks/inventorymanagement2/src/Inventorymanagement2";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Trending2 from "../../blocks/trending2/src/Trending2";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Upvotedownvote from "../../blocks/upvotedownvote/src/Upvotedownvote";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web.";
import EmailOTPVarification from "../../blocks/email-account-login/src/EmailOTPVarification.web";
import AccountActiveProfile from "../../blocks/email-account-login/src/AccountActivativeProfile.web"
import ActiveAddress from "../../blocks/email-account-login/src/ActiveAddress.web";
import DocumentAcitve from "../../blocks/email-account-login/src/DocumentAcitve.web";
import AnalyticsInsights from "../../blocks/analytics/src/Analytics";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary.web";
import Loyaltysystem from "../../blocks/loyaltysystem/src/Loyaltysystem.web";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import CategoriessubcategoriesWebPage from "../../blocks/categoriessubcategories/src/CategoriessubcategoriesPage.web";
import SuccessAddress from "../../blocks/addressmanagement/src/SuccessAddress.web";
import EditAddress from "../../blocks/addressmanagement/src/EditAddress.web"
import OrderPayments from '../../blocks/ordermanagement/src/OrderPayments.web';
import OrderSuccess from '../../blocks/ordermanagement/src/OrderSuccess.web';
import TrendingListPage from '../../blocks/landingpage/src/TrendingListPage.web'
import OrderManagementPage from "../../blocks/ordermanagement/src/OrderManagementPage.web";
import Faqs from '../../blocks/customisableuserprofiles2/src/Faqs.web'
import CurrencyLanguage from '../../blocks/customisableuserprofiles2/src/Currencylanguage.web';
import PairitwithWeb from "../../blocks/productdescription3/src/Pairitwith.web";
import PaymentHistoryWeb from '../../blocks/customisableuserprofiles2/src/PaymentHistory.web'
import SellerStore from '../../blocks/customform/src/SellerStore.web'
import SellerProfile from '../../blocks/customisableuserprofiles2/src/SellerProfile.web';
import HiredStylist from '../../blocks/customisableuserprofiles2/src/HiredStylist.web';
import StylistQuote from '../../blocks/customisableuserprofiles2/src/StylistQuote.web';
import SourceProduct from '../../blocks/customisableuserprofiles2/src/SourceProduct.web';
import CreateSellerStore from '../../blocks/customform/src/CreateSellerStore.web';
import SellersAllOrderDetailsPage from "../../blocks/ordermanagement/src/SellersAllOrderDetails.web";
import SellerBankDetails from '../../blocks/customisableuserprofiles2/src/SellerBankDetails.web';
import Notifications from "../../blocks/notifications/src/Notifications.web";
import MySellerStore from '../../blocks/customform/src/MySellerStore.web';
import NotificationsettingsWeb from "../../blocks/notificationsettings/src/NotificationSettings.web";
import EditSellerStore from '../../blocks/customform/src/EditSellerStore.web';
import ReturnRequest from '../../blocks/ordermanagement/src/ReturnRequest.web';
import SellersOrderDetailsWebPage from "../../blocks/ordermanagement/src/SellersNewDetails/SellersOrderDetails.web";
import SellerStoreProfile from "../../blocks/customform/src/SellerStoreProfile.web";
import CatalogueWeb from "../../blocks/catalogue/src/CatalogueWeb.web";
import SellersInProcessOrderDetails from "../../blocks/ordermanagement/src/SellersInProcessOrderDetails/SellersInProcessOrderDetails.web";
import SellersRejectedOrderDetails from "../../blocks/ordermanagement/src/SellersRejectedOrder/SellersRejectedOrderDetails.web"
import SellerAnalyticsInsights from "../../blocks/customform/src/Seller/AnalyticsInsights/SellerAnalyticsInsights.web";
import EarningsAnalyticsWebPage from "../../blocks/analytics/src/EarningsAnalyticsPage.web"
import BuyerReturnOrderDetailsWebPage from "../../blocks/ordermanagement/src/ReturnOrder/BuyerReturnOrderDetails.web"
import SellerRevenue from "../../blocks/customform/src/Seller/Revenue/SellerRevenue.web";
import SellerCatalogues from "../../blocks/customform/src/Seller/Catalogues/SellerCatalogues.web";
import SellerOffersDiscountsWebPage from "../../blocks/promocodes/src/SellerOffersDiscounts.web";
import SellerOffersDiscountsCreateWebPage from "../../blocks/promocodes/src/SellerOffersDiscountsCreate.web";
import SellerInventoryManagement from "../../blocks/customform/src/Seller/InventoryManagement/SellerInventoryManagement.web";
import SalesReport from "../../blocks/analytics/src/SalesReport.web"
import SellerHelp from "../../blocks/contactus/src/SellerHelp.web";
import SellerOffersStoreFormWebPage from "../../blocks/promocodes/src/SellerOffersStoreForm.web";
import SellerSelectProductsWebPage from "../../blocks/promocodes/src/SellerSelectProducts.web"
import MyActivity from "../../blocks/customisableuserprofiles2/src/MyActivity.web";
import AdminRequest from "../../blocks/customisableuserprofiles2/src/AdminRequest.web";
import StylishDashboard from "../../blocks/dashboard/src/StylishDashboard.web"
import StylistAccountActiveProfile from "../../blocks/email-account-login/src/StylistAccountActivativeProfile.web"
import StylistContactPayment from "../../blocks/email-account-login/src/StylistContactPayment.web"
import StylistPortfolioAcitve from "../../blocks/email-account-login/src/StylistPortfolioAcitve.web"
import StylishProfile from "../../blocks/customisableuserprofiles2/src/Stylish/StylishProfile.web";
import StylistUploadDocumentsweb from "../../blocks/email-account-login/src/StylistUploadDocuments.web"
import SellerStoreDeleteProducts from "../../blocks/customform/src/Seller/InventoryManagement/BulkActions/DeleteProducts.web";
import StylishCatalogue from "../../blocks/catalogue/src/Stylish/StylishCatalogue.web";
import StylishProductSourcing from "../../blocks/catalogue/src/Stylish/StylishProductSourcing.web";
import StylishMyOffer from "../../blocks/catalogue/src/Stylish/StylishMyOffer.web";
import StylishProductSourcingRequest from "../../blocks/catalogue/src/Stylish/StylistProductSourcingRequest.web";
import StylingClientWishlist from "../../blocks/customform/src/Stylist/Clients/StylingClientWishlist.web";
import StylingRequests from "../../blocks/customform/src/Stylist/Clients/StylingRequests.web";
import StylishClientDetails from "../../blocks/customform/src/Stylist/Clients/StylishClientDetails.web";
import StylishClients from "../../blocks/customform/src/Stylist/Clients/StylishClients.web";
import ClientChat from "../../blocks/chat/src/ClientChat.web";
import NewPaymentRequest from "../../blocks/chat/src/ClientNewPaymentRequest.web";
import NewOrderRequest from "../../blocks/chat/src/ClientNewOrderRequest.web";
import StylishMyClients from "../../blocks/customform/src/Stylist/Clients/StylishMyClients.web";
import StylistAnalytics from "../../blocks/analytics/src/StylistAnalytics.web";
import i18n from "../../components/src/i18next/i18n";
import { getStorageData } from '../../framework/src/Utilities';
import SellersAllOrderDetailsWeb from "../../blocks/ordermanagement/src/SellersAllOrderDetails/SellersAllOrderDetails.web"
import BuyerStore from "../../blocks/customform/src/buyer/BuyerStore.web";
import BuyerStoreProduct from "../../blocks/customform/src/buyer/BuyerStoreProduct.web";
import FavouriteStylist from "../../blocks/customisableuserprofiles2/src/FavouriteStylist.web";
import HiredStylistProfile from "../../blocks/customisableuserprofiles2/src/HiredStylistProfile.web"

const routeMap = {
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/Login'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  ResetPassword: {
    component: ResetPassword,
    path: '/ResetPassword'
  },
  ResetOTPPassword: {
    component: ResetOTPPassword,
    path: '/ResetOTPPassword'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  Chat: {
    component: Chat,
    path: '/Chat'
  },
  ChatView: {
    component: ChatView,
    path: '/ChatView'
  },
  StripePayments: {
    component: StripePayments,
    path: '/StripePayments'
  },
  StylistAccountActiveProfile:{
    component: StylistAccountActiveProfile,
    path: '/StylistAccountActiveProfile'
  },
  StylistContactPayment:{
    component: StylistContactPayment,
    path: '/StylistContactPayment'
  },
  StylistPortfolioAcitve:{
    component: StylistPortfolioAcitve,
    path: '/StylistPortfolioAcitve'
  },
  Expressdelivery: {
    component: Expressdelivery,
    path: '/Expressdelivery'
  },
  TermsConditions: {
    component: TermsConditions,
    path: '/Policies'
  },
  AboutFash: {
    component: TermsConditionsDetail,
    path: '/AboutFash'
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: '/TermsConditionsUsers'
  },
  Inventorymanagement2: {
    component: Inventorymanagement2,
    path: '/Inventorymanagement2'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  Trending2: {
    component: Trending2,
    path: '/Trending2'
  },
  Savedcards: {
    component: Savedcards,
    path: '/Savedcards'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: '/Uploadmedia3'
  },
  Upvotedownvote: {
    component: Upvotedownvote,
    path: '/Upvotedownvote'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  TrendingListPage:{
    component:TrendingListPage,
    path:'/TrendingList'

  },
  SplashScree: {
    component: SplashScreen,
    path: '/SplashScreen'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/Signup'
  },
  EmailOTPVarification: {
    component: EmailOTPVarification,
    path: '/OTPVarification'
  },
  AccountActiveProfile: {
    component: AccountActiveProfile,
    path: '/AccountActiveProfile'
  },
  ActiveAddress:{
    component: ActiveAddress,
    path: '/ActiveAddress'
  },
  DocumentAcitve:{
    component: DocumentAcitve,
    path: '/DocumentAcitve'
  },
  CurrencyLanguage: {
    component: CurrencyLanguage,
    path: '/Setting'
  },
  AnalyticsInsights: {
    component: AnalyticsInsights,
    path: '/AnalyticsInsights'
  },
  PhotoLibrary: {
    component: PhotoLibrary,
    path: '/PhotoLibrary'
  },
  Loyaltysystem: {
    component: Loyaltysystem,
    path: '/Loyaltysystem'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  CategoriessubcategoriesWebPage: {
    component: CategoriessubcategoriesWebPage,
    path: '/CategoriessubcategoriesWebPage'
  },
  OrderManagementPage: {
    component: OrderManagementPage,
    path: "/OrderManagementPage",
  },
  ReturnRequest:{
    component: ReturnRequest,
    path: "/ReturnRequest",
  },
  SellerHelp: {
    component: SellerHelp,
    path: "/SellerHelp"
  },
  AddAddress: {
    component: AddAddress,
    path: '/AddAddress'
  },
  SuccessAddress:{
    component:SuccessAddress,
    path:"/SuccessAddress"
  },
  Shippingaddressvalidation2:{
    component:Shippingaddressvalidation2,
    path:"/Shippingaddressvalidation2"
  },
  EditShippingaddressvalidation2:{
    component:EditShippingaddressvalidation2,
    path:"/EditShippingaddressvalidation2"
  },
  AddShippingaddressvalidation2:{
    component:AddShippingaddressvalidation2,
    path: "/AddShippingaddressvalidation2"
  },
  EditAddress:{
    component:EditAddress,
    path:"/EditAddress"
  },
  OrderDetails: {
    component: OrderDetails,
    path: '/OrderDetails/:navigationBarTitleText'
  },
  ReturnStatus: {
    component: ReturnStatus,
    path: '/ReturnStatus/:navigationBarTitleText'
  },
  TrackOrder: {
    component: TrackOrder,
    path: '/TrackOrder/:navigationBarTitleText'
  },
  BuyerReturnOrderDetailsWebPage: {
    component: BuyerReturnOrderDetailsWebPage,
    path: '/BuyerReturnOrderDetailsWebPage/:navigationBarTitleText'
  },
  Customisableuserprofiles2: {
    component: Customisableuserprofiles2,
    path: '/profile'
  },
  SellersAllOrderDetailsWebPage:{
    component: SellersAllOrderDetailsPage,
    path: '/OrderManagement'
  },
  SellerDashboard:{
    component: DashboardWeb,
    path: '/SellerDashboard'
  },
  Wishlist:{
    component: Wishlist,
    path: '/Wishlist'
  },
  FavouriteStylist:{
    component: FavouriteStylist,
    path: '/FavouriteStylist'
  },
  Productdescription3:{
    component:Productdescription3,
    path:"/Productdescription3/:navigationBarTitleText"
},
StoreProduct:{
  component:BuyerStoreProduct,
  path:"/StoreProduct/:navigationBarTitleText"
},
  PairitwithWeb: {
    component: PairitwithWeb,
    path: "/PairitwithWeb/:navigationBarTitleText"
  },
ProductdetailsWeb:{
    component:ProductdetailsWeb,
    path:"/ProductdetailsWeb"
      },
  PaymentDetails:{
    component: PaymentDetails,
    path:"/PaymentDetails"
  },
  SellerProductOverview:{
    component: SellerProductOverview,
    path:"/SellerProductOverview/:navigationBarTitleText"
  },
  SellerReadyForCollectionDetail:{
    component: SellerReadyForCollectionDetail,
    path:"/SellerReadyForCollectionDetail/:navigationBarTitleText"
  },
  SellerDeliveredDetail:{
    component: SellerDeliveredDetail,
    path:"/SellerDeliveredDetail/:navigationBarTitleText"
  },
  OutForDeliveryDetail:{
    component: OutForDeliveryDetail,
    path:"/OutForDeliveryDetail/:navigationBarTitleText"
  },
  ReturnDetail:{
    component: ReturnDetail,
    path:"/ReturnDetail/:navigationBarTitleText"
  },
  PaymentHistoryWeb:{
    component: PaymentHistoryWeb,
    path:"/PaymentHistoryWeb"
  },
  SellersOrderDetailsWebPage:{
    component: SellersOrderDetailsWebPage,
    path:"/SellersOrderDetailsWebPage/:navigationBarTitleText"
  },
  SellersInProcessOrderDetails:{
    component: SellersInProcessOrderDetails,
    path:"/SellersInProcessOrderDetails/:navigationBarTitleText"
  },
  SellersRejectedOrderDetails: {
    component: SellersRejectedOrderDetails,
    path: "/SellersRejectedOrderDetails/:navigationBarTitleText"
  },
  BuyerStore: {
    component: BuyerStore,
    path: "/BuyerStore"
  },
  BuyerStoreProduct: {
    component: BuyerStoreProduct,
    path: "/BuyerStoreProduct"
  },
  EarningsAnalyticsWebPage: {
    component: EarningsAnalyticsWebPage,
    path: "/EarningsAnalyticsWebPage"
  },
  SellerOffersDiscounts: {
    component: SellerOffersDiscountsWebPage,
    path: "/SellerOffersDiscounts"
  },
  SellerOffersDiscountsCreateWebPage:{
    component: SellerOffersDiscountsCreateWebPage,
    path:"/SellerOffersDiscountsCreateWebPage"
  },
  SellerOffersStoreFormWebPage:{
    component: SellerOffersStoreFormWebPage,
    path:"/SellerOffersStoreFormWebPage"
  },
  SellerSelectProductsWebPage:{
    component:SellerSelectProductsWebPage,
    path:"/SellerSelectProductsWebPage"
  },
  StylistUploadDocumentsweb:{
    component:StylistUploadDocumentsweb,
    path:"/StylistUploadDocumentsweb"
  },
  ProductSearch:{
    component:ProductSearch,
    path:"/ProductSearch"
  },
  Home: {
    component: SplashScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  OrderPayments: {
    component: OrderPayments,
    path: '/Payment'
  },
  OrderSuccess: {
    component: OrderSuccess,
    path: '/OrderSuccess'
  },
  Faqs:{
    component:Faqs,
    path: '/Faqs'
  },
  PageNotFound:{
    component: PageNotFound,
    path: '/PageNotFound'
  },
  Contactus:{
    component: Contactus,
    path: '/Contactus'
  },
  SellerStore:{
    component: SellerStore,
    path:'/SellerStore'
  },
  HiredStylist:{
    component: HiredStylist,
    path:'/HiredStylist'
  },
  StylistQuote: {
    component: StylistQuote, 
    path: '/StylistQuote'
  },
  SourceProduct: {
    component: SourceProduct, 
    path: '/SourceProduct'
  },
  SellerProfile:{
    component: SellerProfile,
    path:'/SellerProfile'
  },
  StylishProfile: {
    component: StylishProfile,
    path:'/StylishProfile'
  },
  SellerBankDetails:{
    component: SellerBankDetails,
    path: '/BankDetails'
  },
  CreateSellerStore:{
    component: CreateSellerStore,
    path:'/CreateSellerStore'
  },
  Notifications:{
    component: Notifications,
    path:'/Notifications'
  },
  MyStore:{
    component: MySellerStore,
    path:'/MyStore'
  },
  Notificationsettings:{
    component: NotificationsettingsWeb,
    path:'/Notificationsettings'
  },
  SellerStoreProfile: {
    component: SellerStoreProfile,
    path: '/SellerStoreProfile'
  },
  Catalogue: {
    component: CatalogueWeb,
    path: '/Catalogue'
  },
  ProductDetail:{
    component: ProductDetail,
    path: '/ProductDetail'
  },
  EditCatalougeWeb:{
    component: EditCatalougeWeb,
    path: "/EditCatalougeWeb"
  },
  EditSellerStore:{
    component: EditSellerStore,
    path:'/EditSellerStore'
  },
  SellerAnalyticsInsights:{
    component: SellerAnalyticsInsights, 
    path: '/SellerAnalyticsInsights'
  },
  SellerRevenue:{
    component: SellerRevenue,
    path: '/SellerRevenue',
  },
  SellerCatalogues: {
    component: SellerCatalogues,
    path: '/SellerCatalogues'
  },
  InventoryManagement: {
    component: SellerInventoryManagement,
    path: '/InventoryManagement'
  },
  SellerSalesReport: {
    component: SalesReport,
    path: '/SalesReport'
  }, 
  AdminRequest: {
    component: AdminRequest,
    path: "/AdminRequest"
  },
  MyActivity: {
    component: MyActivity,
    path: "/MyActivity"
  },
  StylishDashboard: {
    component: StylishDashboard,
    path: "/StylishDashboard"
  },
  SellerStoreDeleteProducts: {
    component: SellerStoreDeleteProducts,
    path: "/SellerStoreDeleteProducts"
  },
  StylishCatalogue: {
    component: StylishCatalogue,
    path: "/StylishCatalogue"
  },
  StylishProductSourcing: {
    component: StylishProductSourcing,
    path: "/StylishProductSourcing"
  },
  StylishMyOffer: {
    component: StylishMyOffer,
    path: "/StylishMyOffer"
  },
  StylishProductSourcingRequest: {
    component: StylishProductSourcingRequest,
    path: "/StylishProductSourcingRequest"
  },
  StylingClientWishlist : {
    component: StylingClientWishlist,
    path: "/StylingClientWishlist",
  },
  StylingRequests : {
    component: StylingRequests,
    path: "/StylingRequests",
  },
  StylishClientDetails : {
    component: StylishClientDetails,
    path: "/StylishClientDetails",
  },
  StylishClients : {
    component: StylishClients,
    path: "/StylishClients",
  },
  ClientChat : {
    component: ClientChat,
    path: "/ClientChat",
  },
  NewPaymentRequestId : {
    component: NewPaymentRequest,
    path: "/NewPaymentRequest/:id",
  },
  NewOrderRequestId : {
    component: NewOrderRequest,
    path: "/NewOrderRequest/:id",
  },
  StylishMyClients : {
    component: StylishMyClients,
    path: "/StylishMyClients",
  },
  StylistAnalytics : {
    component: StylistAnalytics,
    path: "/AnalyticsReport",
  },
  SellersAllOrderDetailsWeb:{
    component:SellersAllOrderDetailsWeb,
    path:"/SellersAllOrderDetailsWeb/:navigationBarTitleText"
  },
  HiredStylistProfile:{
    component:HiredStylistProfile,
    path:"/HiredStylistProfile/:navigationBarTitleText"
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {

  async componentDidMount() {
    const currentLang = await getStorageData('lang');
    if (currentLang) {
        i18n.changeLanguage(currentLang);
    }
  }
  
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
        <View style={{ height: '100vh', width: '100%', overflowY: 'auto' ,overflowX:"hidden"}}>
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
    );
  }
}

export default App;
