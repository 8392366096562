import React from "react";
// Customizable Area Start
import Header from "../../../components/src/Header";
import HiredStylistProfileController, {
    Props,
    ImagesEntity,
} from "./HiredStylistProfileController.web";
import Alert from '@material-ui/lab/Alert';
import Footer from "../../../components/src/Footer";
import { Grid, Breadcrumbs, Typography, Avatar, styled, Snackbar, Modal, withStyles, Box, Checkbox, CheckboxProps, CardContent as MuiCardContent, Divider, IconButton, Tabs, Tab, Card, Button } from "@material-ui/core";
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import { message as messageIcon, Noimageavailable } from "./assets"
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
// Customizable Area End

export default class HiredStylistProfile extends HiredStylistProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    generateAlternatingImageRows() {
        const rowsImg: JSX.Element[] = [];
        let index = 0;
        while (index < this.state.visibleCount && index < this.state.explorePortfolioList.length) {
            if (index + 7 <= this.state.explorePortfolioList.length) {
                rowsImg.push(this.createImageGalleryRows(index, index + 1));
                index += 7;
            } else if (index < this.state.explorePortfolioList.length) {
                rowsImg.push(this.createImageGalleryRows(index, index + 1));
                index = this.state.explorePortfolioList.length;
            }
            if (index + 5 <= this.state.explorePortfolioList.length) {
                rowsImg.push(this.generateReversedImageRow(index));
                index += 5;
            } else if (index < this.state.explorePortfolioList.length) {
                rowsImg.push(this.generateReversedImageRow(index));
                index = this.state.explorePortfolioList.length;
            }
        }
        return rowsImg;
    }

    createImageGalleryRows = (largeIndex: number, smallStartIndex: number) => {
        const largeImage = this.state.explorePortfolioList[largeIndex];
        return (
            <Grid spacing={2} container key={`row-${largeIndex}`}>
                <Grid item xs={12} sm={6}>
                    <img alt={largeImage && largeImage.description} className="largeImage" src={largeImage && largeImage.image_url} />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Grid spacing={2} container>
                        {this.state.explorePortfolioList.slice(smallStartIndex, smallStartIndex + 6).map((item: ImagesEntity, index: number) => (
                            <Grid item xs={6} sm={4} id="smallStartIndex" key={`small-${smallStartIndex + index}`}>
                                <img alt={item.description} className="smallImage" src={item.image_url} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    generateReversedImageRow = (startIndex: number) => {
        const totalRemainIng = this.state.explorePortfolioList.length - startIndex;
        const smallImageCountImg = Math.min(totalRemainIng, 4);
        return (
            <Grid container spacing={2} key={`reverse-row-${startIndex}`}>
                <Grid item xs={12} sm={6} id="startIndex">
                    <Grid container spacing={2}>
                        {this.state.explorePortfolioList.slice(startIndex, startIndex + smallImageCountImg).map((item: ImagesEntity, index: number) => (
                            <Grid item xs={6} sm={6} key={`reverse-small-${startIndex + index}`}>
                                <img src={item && item.image_url} alt={item && item.description} id="startIndex" className="smallImage" />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                {totalRemainIng >= 5 && (
                    <Grid item xs={12} sm={6}>
                        <img src={this.state.explorePortfolioList[startIndex + 4]?.image_url} alt={this.state.explorePortfolioList[startIndex + 4]?.description} className="largeImage" />
                    </Grid>
                )}
            </Grid>
        );
    };


    renderBreadcrumbsWithTitle = (selected: number) => {
        return (<BreadcrumbStyle>
            {selected === 0 && (
                <>
                    <Breadcrumbs aria-label="breadcrumb" className="breadcrumb" separator={<KeyboardArrowRightRoundedIcon />}>
                        <Typography color="inherit" style={{ cursor: 'pointer' }} onClick={this.hiredStylistPage} data-testid="hiredStylistPage" id="Stylist">Stylist</Typography>
                        <Typography color="inherit" id="Stylist" style={{ cursor: 'pointer' }} onClick={this.hiredStylistPage}>Stylists Portfolios</Typography>
                        <Typography color="inherit" id="Stylist">Stylist's Profile</Typography>
                        <Typography color="inherit" id="Stylist">Hire Stylist Plans</Typography>
                    </Breadcrumbs>
                </>
            )}
            {(selected === 1 || selected === 2) && (
                <>
                    <Breadcrumbs aria-label="breadcrumb" className="breadcrumb" separator={<KeyboardArrowRightRoundedIcon />}>
                        <Typography color="inherit" style={{ cursor: 'pointer' }} onClick={this.hiredStylistPage} data-testid="hiredStylistPage" id="Stylist">Stylist</Typography>
                        <Typography color="inherit" id="Stylist" style={{ cursor: 'pointer' }} onClick={this.hiredStylistPage}>Stylists Portfolios</Typography>
                        <Typography color="inherit" id="Stylist">Stylist's Profile</Typography>
                    </Breadcrumbs>
                </>
            )}
            <Box className="pageTitle">
                <IconButton data-testid="handleBackButton" onClick={this.hiredStylistPage} className="iconButton"> <KeyboardArrowLeftRoundedIcon /></IconButton>
                <Typography className="text">Stylist's Profile</Typography>
            </Box>
        </BreadcrumbStyle>)
    }

    StylistCard = () => {
        return (
            <>
                <StylistCardContainer>
                    <Box className="avatar-box">
                        <Avatar className="avatar" src={this.state.stylistProfileData?.attributes?.profile_picture ?? undefined} alt={this.state.stylistProfileData?.attributes?.full_name ?? 'Default Name'} />
                        <Box>
                            <Typography variant="h6" className="name-text">{this.state.stylistProfileData && this.state.stylistProfileData.attributes.full_name}</Typography>
                            <Typography variant="body2" className="available-text">Available to Hire</Typography>
                        </Box>
                        <IconButton className="favorite-icon" data-testid="favBtn" onClick={() => this.toggleFavorite()}>
                            {this.state.stylistProfileData?.attributes.is_favorite ? <FavoriteIcon fill="#FF0000" /> : <FavoriteBorderIcon fill="#375280" />}
                        </IconButton>
                    </Box>
                    <Typography variant="body2" className="description">
                        {this.state.stylistProfileData?.attributes.bio}
                    </Typography>
                    <Box className="action-box">
                        <Box className="action-button">
                            <CallOutlinedIcon className="icon" />
                            <Typography variant="body2">Request a callback</Typography>
                        </Box>
                        <Box className="action-button">
                            <img src={messageIcon} className="icon" />
                            <Typography variant="body2">Chat with Annette</Typography>
                        </Box>
                    </Box>
                    <Divider style={{ margin: '16px 0' }} />
                    <Button fullWidth className="hire-button">Hire Stylist</Button>
                </StylistCardContainer>
            </>
        );
    };
    renderExplorePortfolio = () => {
        const { explorePortfolioList, visibleCount } = this.state;
        return (
            <>
                <ImageTitle>Explore Portfolio</ImageTitle>
                <ImageCollage>
                    {explorePortfolioList.length ? this.generateAlternatingImageRows() : <StylistRow><div className="nodata">No portfolio images found</div></StylistRow>}
                </ImageCollage>
                {(visibleCount < explorePortfolioList.length) &&
                    <Box className="viewMore">
                        <Button variant="outlined" className="viewMoreButton" onClick={this.handleViewMore} data-testid="viewMoreButton">
                            View More
                        </Button>
                    </Box>
                }
            </>
        )
    }
    renderProductCards = () => {
        const { cardStylist } = this.state;
        return (
            <Grid container spacing={3} style={{ marginTop: "15px" }}>
                {cardStylist && cardStylist.length > 0 ?
                    cardStylist.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                            <ProductCardWrapper data-testid="navigateTo" onClick={() => this.navigateTo({ productId: item.id, props: this.props, screenName: "Productdescription3" })}>
                                <div className="cardProductCards">
                                    <img
                                        className="productImageProductCards"
                                        src={item.attributes.primary_image === null ? Noimageavailable : item.attributes.primary_image}
                                        alt={item.attributes.name || "Product image"}
                                    />
                                    <div className="discountBadgeProductCards">15% Off</div>
                                    <IconButton className="favoriteIconProductCards" data-testid="isWishlistData" onClick={(event) => this.is_wishlistData(item, event)}>
                                        {item && item.attributes.is_wishlist ? <FavoriteIcon fill="#FF0000" /> : <FavoriteBorderIcon fill="#375280" />}
                                    </IconButton>
                                    <div className="productInfoProductCards">
                                        <div>
                                            <p className="productNameProductCards">{item.attributes.name}</p>
                                            <p className="brandNameProductCards">{item.attributes.description}</p>
                                        </div>
                                        <p className="price">${item.attributes.primary_price}</p>
                                    </div>
                                </div>
                            </ProductCardWrapper>
                        </Grid>
                    ))
                    :
                    <StylistRow><div className="nodata">No portfolio images found</div></StylistRow>
                }
            </Grid>
        );
    }


    quarterlyPlan = (name: string) => {
        switch (name) {
            case "quarterly_plan":
                return "Quarterly Plan";
            case "weekly_plan":
                return "Weekly Plan";
            case "monthly_plan":
                return "Monthly Plan";
            default:
                break;
        }
    }
    renderPricingOptions = () => {
        return (
            <Grid container spacing={3} style={{ marginTop: "15px" }}>
                {this.state.productService.map((item, index) =>
                    <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                        <CardWrapper>
                            <MuiCardContent className="contentPricingOptions">
                                <div className="titleRowPricingOptions">
                                    <Typography className="title" variant="h6">
                                        {this.quarterlyPlan(item.attributes.name)}
                                    </Typography>
                                    <Typography className="price" variant="h6">
                                        ${item.attributes.service_charges}/-
                                    </Typography>
                                </div>
                                <div className="dividerPricingOptions" />
                                <ul className="featureListPricingOptions">
                                    <li className="featureItemPricingOptions">{item.attributes.styling_per_week}&nbsp;stylings per week</li>
                                    <li className="featureItemPricingOptions">{item.attributes.discussion_time}&nbsp;hours of discussion time</li>
                                    <li className="featureItemPricingOptions">Voice and Video call facility</li>
                                </ul>
                                <div style={{ color: '#94A3B8', fontSize: '14px', fontFamily: 'Lato', fontWeight: 500 }}>These plans are not subscriptions. They are one-time payment options.</div>
                                <Box style={{ marginTop: '70px', }}>
                                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                                        <CustomCheckbox data-testid="setpricechecked" inputProps={{ 'data-testid': 'setpricechecked' } as React.InputHTMLAttributes<HTMLInputElement> & { 'aria-label'?: string; 'data-testid'?: string; }} checked={this.state.isChecked} onChange={this.handleCheckboxChange} />
                                        &nbsp;
                                        <div >
                                            <div style={{ color: '#94A3B8', fontSize: '14px', fontFamily: 'Lato', fontWeight: 500 }}>I agree to the stylist’s </div>
                                            <div style={{ color: '#94A3B8', fontSize: '14px', fontFamily: 'Lato', fontWeight: 500 }} > <span style={{ color: '#375280', fontSize: '14px', fontFamily: 'Lato', fontWeight: 500, borderBottom: '1px solid #375280', cursor: 'pointer' }} data-testid="policiesStylistPage" onClick={this.policiesStylistPage}>Terms and Conditions</span>&nbsp;of services.
                                            </div>
                                        </div>
                                    </div>
                                    <Button className="planButtonPricingOptions" data-testid="buybutton" onClick={() => this.buybutton(item.id)} fullWidth>
                                        Plan Buy
                                    </Button>
                                </Box>
                            </MuiCardContent>
                        </CardWrapper>
                    </Grid>
                )}
            </Grid>
        )
    }
    // Customizable Area End
    render() {
        // Customizable Area Start 
        const { selected } = this.state;
        return (
            <>
                <PageWrapper>
                    <Header navigation={this.props.navigation} />
                    <Grid container style={{ maxWidth: "1440px", margin: "40px auto", width: "100%" }}>
                        <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                            {this.renderBreadcrumbsWithTitle(selected)}
                            <Grid container spacing={3}>
                                <Grid item xl={4} lg={4} md={4} sm={12} xs={12} >
                                    <LeftBox>
                                        {this.StylistCard()}
                                    </LeftBox>
                                </Grid>
                                <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                                    <WrapperBox>
                                        <CustomTabs value={selected} data-testid="exploreStylist" onChange={this.handleChange}>
                                            <CustomTab selected={selected === 0} label="Pricing" />
                                            <CustomTab selected={selected === 1} label={`Products by ${this.state.stylistProfileData && this.state.stylistProfileData.attributes.full_name}`} />
                                            <CustomTab selected={selected === 2} label="Explore Portfolio" />
                                        </CustomTabs>
                                        {selected === 0 && this.renderPricingOptions()}
                                        {selected === 1 && this.renderProductCards()}
                                        {selected === 2 && this.renderExplorePortfolio()}
                                    </WrapperBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Footer navigation={this.props.navigation} />
                </PageWrapper>
                <Snackbar
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    open={this.state.isAlertOrder}
                    style={{ marginTop: '10px' }}
                    autoHideDuration={3000}
                    onClose={this.oncloseAlertInProcess}
                    data-testid="oncloseAlert"
                ><Alert variant="filled" severity={this.state.alertType}>{this.state.already}</Alert></Snackbar>
                <Modal open={this.state.isModalOpen} style={{ display: "flex" }}>
                    <Box style={webStyle.modalBox} >
                        <Typography style={webStyle.modalHead}>Confirmation</Typography>
                        <Typography style={{ fontSize: "16px", color: '#375280', fontFamily: 'Lato', fontWeight: 500 }}>
                            Your stylist will review your request and contact you.
                        </Typography>
                        <Button style={{ ...webStyle.placeOrderBtn2 }} data-testid="proceedToTrackOrderPage" onClick={this.proceedToTrackOrderPage}>
                            My Requests
                        </Button>
                    </Box>
                </Modal>
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const webStyle = {
    placeOrderBtn2: {
        width: "75%",
        backgroundColor: "#C7B9AD",
        marginTop: "1rem",
        padding: "1rem 0",
        fontSize: "1.125rem",
        fontWeight: 700,
        fontFamily: "Lato, sans-serif",
        color: "#fff",
        textTransform: "none" as "none",
    },
    modalHead: {
        fontSize: "24px",
        fontWeight: 800,
        marginBottom: "0.5rem",
        color: "#375280"
    },
    modalBox: {
        background: "#fff",
        width: "30vw",
        margin: "auto",
        borderRadius: "2px",
        padding: "3rem",
        textAlign: "center" as "center",
    },
}
const BreadcrumbStyle = styled(Box)({
    margin: "20px",
    "& .pageTitle": {
        alignItems: "center",
        display: "flex",
        margin: "20px 0"
    },
    "& .iconButton": {
        borderRadius: '50%',
        backgroundColor: '#fff',
        padding: '8px',
        marginRight: '8px',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
    },
    "& .text": {
        fontSize: '18px',
        fontFamily: 'Lato',
        color: '#375280',
        marginLeft: "5px",
        fontWeight: 600,
    },
    "& .breadcrumb": {
        fontSize: '14px',
        fontFamily: 'Lato',
        lineHeight: '22px',
        fontWeight: 400,
        textDecoration: 'none',
        color: '#475569',
        textAlign: 'left',
    }
})
const CustomTabs = styled(Tabs)({
    borderRadius: '2px',
    '& .MuiTabs-indicator': {
        display: 'none',
    },
});

const WrapperBox = styled(Box)({
    margin: '20px',
    '@media(min-width: 821px)': {
        margin: '0px',
    },
    "& .viewMore": {
        justifyContent: "center",
        display: "flex",
    },
    "& .viewMoreButton": {
        color: '#375280',
        borderColor: '#CCBEB1',
        fontWeight: 700,
        textTransform: 'none',
        padding: '8px 24px',
        fontSize: '16px',
        marginTop: '20px',
        background: "#fff",
        borderRadius: '2px',
        width: "156px",
        cursor: "pointer",
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
    },
    "& .searchBox": {
        width: '380px',
        marginBottom: '24px',
        background: "white",
        borderRadius: '2px',
        float: "right",
        border: '2px solid #CBD5E1',
        "& fieldset": {
            borderColor: "white",
            '&:focus': {
                border: 'none',
                outline: 'none',
            }
        },
        "& .searchIcon": {
            height: "20px",
            marginRight: "15px",
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
                borderWidth: 0,
            },
            '& fieldset': {
                borderColor: 'white',
            },
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: '16px',
            fontFamily: 'Lato',
            color: '#94A3B8',
            fontWeight: 400,
        },
        "& .Mui-focused": {
            outline: "none",
            borderColor: "white",
            border: 'none',
        },
        '& .MuiInputBase-input': {
            '&:focus': {
                outline: 'none',
            },
        },
    },
})

const CustomTab = styled(Tab)({
    borderRadius: '2px',
    height: '40px',
    color: '#375280',
    padding: '11px 20px',
    fontWeight: 500,
    backgroundColor: '#fff',
    textTransform: "none",
    border: "none",
    cursor: "pointer",
    marginRight: "8px",
    '&:hover': {
        color: '#375280',
        backgroundColor: '#ddd',
    },
    "&.Mui-selected": {
        color: '#fff',
        backgroundColor: '#375280',
        fontWeight: 700,
        '&:hover': {
            backgroundColor: '#375280'
        },
    }
});

const StylistRow = styled(Box)({
    justifyContent: "space-between",
    display: "flex",
    padding: "18px 0",
    width: "100%",
    alignItems: "center",
    "& .messageIcon": {
        fill: "#375280",
        height: "32px",
        width: "32px",
        cursor: "pointer",
        marginRight: "10px"
    },
    "& .nodata": {
        fontFamily: 'Lato',
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '24px',
        textAlign: 'center',
        color: "#94A3B8",
        paddingTop: "10px",
        width: "100%"
    }
});

const ImageTitle = styled(Box)({
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: "Lato",
    color: "#375280",
    display: "flex",
    padding: "25px 0",
    alignItems: "center",
    justifyContent: "space-between",
});

const LeftBox = styled(Box)({
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    background: "white",
    margin: "0 20px",
    padding: "20px 30px",
})

const PageWrapper = styled(Box)({
    background: "#F8F8F8"
})
const ImageCollage = styled(Box)({
    "& .largeImage": {
        height: "296px",
        width: "100%",
        padding: "0px 16px 8px 0px",
        objectFit: "cover",
    },
    "& .smallImage": {
        height: "140px",
        width: "100%",
        objectFit: "cover",
    },
});

const StylistCardContainer = styled(Card)({
    borderRadius: '0px',
    padding: '0px',
    boxShadow: "none",
    '& .avatar-box': {
        alignItems: 'center',
        display: 'flex',
        marginBottom: '16px',
    },
    '& .avatar': {
        height: 56,
        marginRight: '12px',
        width: 56,
    },
    '& .name-text': {
        textTransform: 'capitalize',
        fontWeight: 600,
    },
    '& .available-text': {
        fontSize: '14px',
        color: '#059669',
    },
    '& .description': {
        fontFamily: "Lato",
        color: '#6B7280',
        marginTop: '16px',
    },
    '& .action-box': {
        justifyContent: 'space-between',
        padding: '16px 0 0',
        display: 'flex',

    },
    '& .action-button': {
        alignItems: 'center',
        display: 'flex',
        justifyContent: "space-between",
        width: "50%",
        flexDirection: "column",
        "&:first-child": {
            borderRight: "1px solid #E2E8F0"
        },
        "& p": {
            fontSize: '14px',
            fontFamily: 'Lato',
            textAlign: 'center',
            lineHeight: '24px',
            fontWeight: 700,
            color: '#375280',
        }
    },
    '& .icon': {
        fill: "#375280",
        marginRight: '8px',
        marginBottom: '10px',
        height: '25px',
    },
    '& .hire-button': {
        textTransform: "none",
        backgroundColor: '#D1BEA8',
        color: 'white',
        fontSize: "20px",
        height: '56px',
        fontWeight: 'bold',
        borderRadius: '2px 0px 0px 0px',
        padding: '10px 16px',
        "& span": {
            borderRadius: '2px 0px 0px 0px',
        },
        '&:hover': {
            backgroundColor: '#b59f8d',
        },
    },
    '& .favorite-icon': {
        marginLeft: 'auto',
        '& svg': {
            fill: "#375280"
        }
    },
});

const ProductCardWrapper = styled(Box)({
    width: "100%",
    position: "relative",
    backgroundColor: "#fff",
    borderRadius: "2px",
    cursor: "pointer",
    transition: "0.3s ease-in-out",
    "& .discountBadgeProductCards": {
        top: "15px",
        position: "absolute",
        backgroundColor: "#fff",
        left: "15px",
        fontSize: "12px",
        color: "#375280",
        padding: "4px 8px",
        fontWeight: "700",
        fontFamily: "Lato",
        borderRadius: "2px",
    },
    "& .favoriteIconProductCards": {
        right: "15px",
        position: "absolute",
        color: "#375280",
        fontSize: "24px",
        height: "30px",
        width: "30px",
        padding: "8px",
        top: "15px",
        background: "white"
    },
    "& .productImageProductCards": {
        height: "350px",
        objectFit: "cover",
        width: "100%",
    },

    "& .productInfoProductCards": {
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        padding: "16px",
        "& .productNameProductCards": {
            fontFamily: "Lato",
            color: "#375280",
            fontWeight: 700,
            lineHeight: "19.2px",
            fontSize: "16px",
            margin: "10px 0 5px",
            textAlign: "left",
        },
        "& .brandNameProductCards": {
            fontSize: "14px",
            fontFamily: "Lato",
            lineHeight: "16.8px",
            fontWeight: 500,
            margin: "5px 0 10px",
            textAlign: "left",
        },
        "& .price": {
            color: '#375280',
            backgroundColor: '#F8F8F8',
            padding: '10px',
            fontFamily: 'Lato',
            borderRadius: "8px",
            fontWeight: "700",
        },
    },
})

const CardWrapper = styled(Card)({
    borderRadius: '2px',
    maxWidth: '400px',
    border: "1px solid #D5D5D5",
    boxShadow: "none",
    padding: '20px',
    "& .contentPricingOptions": {
        padding: "0px"
    },
    "& .titleRowPricingOptions": {
        justifyContent: 'space-between',
        display: 'flex',
        marginBottom: '8px',
        alignItems: 'center',
    },
    "& .title": {
        fontSize: '20px',
        fontFamily: 'Lato',
        lineHeight: '24px',
        fontWeight: 700,
        color: "#375280",
        textAlign: 'left',
    },
    "& .price": {
        fontSize: '20px',
        fontFamily: 'Lato',
        lineHeight: '24px',
        fontWeight: 800,
        color: '#375280',
        textAlign: 'right',
    },
    "& .dividerPricingOptions": {
        backgroundColor: '#e0e0e0',
        height: '1px',
        margin: '16px 0',
    },
    "& .featureListPricingOptions": {
        margin: '0',
        padding: '0 20px',
        color: '#6b6b6b',
        listStyle: 'disc',
        fontSize: '16px',
        fontFamily: 'Lato',
        lineHeight: '24px',
        fontWeight: 500,
        textAlign: 'left',
    },
    "& .featureItemPricingOptions": {
        fontSize: '16px',
        color: '#375280',
        padding: '2px 0',
    },
    "& .planButtonPricingOptions": {
        color: 'white',
        backgroundColor: '#cbb9a8',
        borderRadius: '2px',
        fontFamily: 'Lato',
        fontSize: '20px',
        textTransform: "none",
        lineHeight: '26px',
        fontWeight: 800,
        padding: "10px",
        letterSpacing: '0.01em',
        '&:hover': {
            backgroundColor: '#bda893',
        },
    },
})
const CustomCheckbox = withStyles({
    root: {
        color: '#CCBEB1',
        '&$checked': {
            color: '#CCBEB1',
        },
    },
    icon: {
        width: 16,
        borderRadius: 3,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        height: 16,
        backgroundImage: 'linear-gradient(180deg, hsla(0,0%,100%,.8), hsla(0,0%,100%,0))',
        backgroundColor: '#f5f8fa',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            background: 'rgba(206,217,224,.5)',
            boxShadow: 'none',
        },
    },
    checked: {
        color: '#CCBEB1',
    },
    checkedIcon: {
        backgroundColor: '#CCBEB1',
    },
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
// Customizable Area End
