import {enHeader, arHeader} from './Headerlang'
import { enSidebar, arSidebar } from "./siderbarLang";
import {enFooter, arFooter} from './footerLang'
import { enLoginSignup, arLoginSignup } from "../../../blocks/utilities/src/Language/loginSignupLang"
import { enProdDesc, arProdDesc} from "./productDescLang"
import { enCategoryFilter, arCategoryFilter } from "../../../blocks/utilities/src/Language/CategoriesFilterLang"
import { enCart, arCart } from "../../../blocks/utilities/src/Language/ShoppingCartLang"

export const availableResources = {
    en: {
      header: enHeader,
      sidebar: enSidebar,
      footer: enFooter,
      loginSignup: enLoginSignup,
      prodDesc: enProdDesc,
      categoryFilter: enCategoryFilter,
      shoppingCart: enCart
    },
    ar: {
      header: arHeader,
      sidebar: arSidebar,
      footer: arFooter,
      loginSignup: arLoginSignup,
      prodDesc: arProdDesc,
      categoryFilter: arCategoryFilter,
      shoppingCart: arCart
    }
}
