// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { wishlistProduct1, wishlistProduct2, wishlistProduct3, wishlistProduct6 } from "../assets";
import { getStorageData } from "framework/src/Utilities";

interface INavigateDetailsPageTo {
  id: number | undefined;
  screenName: string;
  raiseMessage?: Message;
}
// Customizable Area End


export const configJSON = require("../../src/config.js");


export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  storeList: any;
  circularProgress: boolean;
  token: string;
  isAlert:boolean;
  alertType: 'success' | 'error' | 'warning' | 'info';
  alertMsg: string;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BuyerStoreProductController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchStoreDetailsCallID: string = "";
  postFavouriteApiCalledId: string  = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      storeList: [],
      circularProgress: false,
      token: "",
      isAlert: false,
      alertType: 'success',
      alertMsg: "",
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiRequestCallId === this.postFavouriteApiCalledId){
      if(responseJson?.error){
        this.setState({
          isAlert: true,
          alertType: "error",
          alertMsg: responseJson?.error
        })
      }else{
        this.setState({
          isAlert: true,
          alertType: "success",
          alertMsg: "Added to wishlist"
        }, () => this.getbuyerStores())
      }
    }else if (apiRequestCallId === this.fetchStoreDetailsCallID) {
      if (responseJson.errors) {
        this.setState({
          isAlert: true,
          alertType: "error",
          alertMsg: responseJson.errors.length > 0 ? responseJson.errors[0].token : responseJson.errors ,
          circularProgress: false
        });
      }else{
        this.setState(({
          storeList: responseJson?.data,
            circularProgress: false,
        }))
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    const authToken = await getStorageData('auth-token')
    this.getbuyerStores();
    this.setState({
      token: authToken
    })

    // Customizable Area End
  }

  navigateToDeatils = ({id,screenName,raiseMessage}: INavigateDetailsPageTo) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    id && 
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    raiseMessage &&
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage(message.id, message);
};

handleFavouriteApi = (favouriteId: string) => {
    this.handleAddWishlistApiCall(favouriteId);
}

oncloseAlert = () => {
  this.setState({
    isAlert: false
  })
}
handleAddWishlistApiCall = async (favouriteId?: string) => {
  const headers = {
    "Content-Type": configJSON.exampleApiContentType,
    token: this.state.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  const httpBody = {
    data: {
      favouriteable_id: favouriteId
    }
  };

  this.postFavouriteApiCalledId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.postFavouriteApi
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

  getbuyerStores = async () => {
    const storeId = this.props.navigation.getParam("navigationBarTitleText");
    this.setState({
      circularProgress: true
    })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchStoreDetailsCallID = requestMessage.messageId;
    
    const apiRoute =  `${configJSON.getParticularStore}/${storeId}`

    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiRoute
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
