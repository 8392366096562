import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { apiCall } from "../../../components/src/APICall";
import { getStorageData } from "../../../framework/src/Utilities";


type AlertSnackType = 'success' | 'error' | 'warning' | 'info';

export interface Attributes {
  id: number | string;
  name: string;
  candidate_name: string;
  unread_message_count: number;
  is_mark_read: boolean;
  last_message_type: string;
  candidate_role: string;
  profile?: string; 
}

interface ChatObject {
  id: string;
  type: string;
  attributes: Attributes;
  relationships: { accounts: {
    data: {
      id: string;
      type: string;
    }[];
  }};
}

interface ClientListResponse {
  data: ChatObject[];
  error?: string;
  errors?: string[]
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  selectedClient: Attributes | null,
  anchorElClientsChat: HTMLElement | null;
  query: string;
  chatId: string | number;
  clientPageNum: number | null;
  clientTotalPage: number;
  hasmoreClients: boolean;
  clientList: ChatObject[];
  stylistFullName: string;
  stylistProfile: string;
  isLoad: boolean;
  stylistId: string;
  receiverId: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: AlertSnackType;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ClientChatController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  private debounceTimeout: NodeJS.Timeout | null = null;
  getClientsAPICallId: string = ""
  getStylistInfoAPICallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      selectedClient: null,
      anchorElClientsChat: null,
      query: "",
      chatId: "",
      clientPageNum: 1,
      clientTotalPage: 0,
      hasmoreClients: false,
      clientList: [],
      stylistFullName: "",
      stylistProfile: "",
      isLoad: false,
      isAlert: false,
      alertMsg: "",
      alertType: 'success' as AlertSnackType,
      stylistId: "",
      receiverId: "",
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if(responseJson && !responseJson.errors) {
          if(this.getClientsAPICallId === apiRequestCallId){
            this.getClientListResponse(responseJson)
          }
          if(this.getStylistInfoAPICallId === apiRequestCallId){
            this.getStylistInfoResponse(responseJson)
          }
      } else {
        this.setState({ isLoad: false})
        if(responseJson.errors) {
          const errorMessages = responseJson.errors.map((error: { [key:string]: string}) => Object.values(error)[0]);
          this.setState({
            isAlert: true,
            alertMsg: errorMessages,
            alertType: 'error'
          })
        } else {
          this.setState({
            isAlert: true,
            alertMsg: "Something went wrong",
            alertType: 'error'
          })
        }
      } 
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    this.setState({ isLoad: true})
    this.getStylistInfo()
    this.getClientsList()
    // Customizable Area End
  }

  handleClientClick = async (client: ChatObject) => {
    const receiverId = await this.getNonStylishId(client);
    this.setState(
      { selectedClient: client.attributes, receiverId: receiverId, chatId: client.id }
    );
  };

  getNonStylishId = async (responseJson: ChatObject): Promise<string> => {
    const stylishId = this.state.stylistId;
    const accounts = responseJson?.relationships?.accounts?.data;
    const nonStylishAccount = accounts.find((account: { id: string; type: string }) => account.id !== stylishId);
    return nonStylishAccount?.id || "";
  };

  handleClickChatMore = (event: React.MouseEvent<HTMLImageElement>) => {
    this.setState({ anchorElClientsChat: event?.currentTarget });
};

navigateToClient = () => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), "StylishClients");
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
}

navigateToNewPaymentReq = (component: string) => {
  if(component==="NewOrderRequestId" || component==="NewPaymentRequestId")
    this.props.navigation.navigate(component, {id: this.state.chatId} );
}

handleCloseChatMore = () => {
  this.setState({ anchorElClientsChat: null });
};

debounceClientChatSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;

  if (this.debounceTimeout) {
    clearTimeout(this.debounceTimeout);
  }

  this.debounceTimeout = setTimeout(() => {
    this.setState({ query: value }, () => {
      this.getClientsList()
    });
  }, 300);
}

async componentWillUnmount() {
  if (this.debounceTimeout) {
    clearTimeout(this.debounceTimeout);
  }
}

getClientsList = async () => {
  const { clientPageNum, query} = this.state;
  if (!clientPageNum || clientPageNum === null) {
    this.setState({ hasmoreClients: false })
    return
  }

  const clientListToken  = await getStorageData("auth-token")

  const clientListBody = {
    query: query,
    per_page: "10",
    page: clientPageNum
  }

  const endPointOfClientList = `${configJSON.getClientChatsEndPoint}`
  
  this.getClientsAPICallId = await apiCall({
    token: clientListToken,
    contentType: configJSON.apiContentType,
    method: configJSON.postApiMethod,
    endPoint: endPointOfClientList,
    body: clientListBody,
    isJsonStringify: true,
  });
}

getClientListResponse = (resp: ClientListResponse) => {
  if(resp.data && Array(resp.data)){
    const listData = resp.data.map( (data) => {
      return data
    })
    this.setState({
      clientList: this.state.clientList.concat(listData)
    })
  }
}

getStylistInfo = async () => {
  const stylist_id = localStorage.getItem('user_id');
  const stylistToken = await getStorageData("auth-token");

  this.getStylistInfoAPICallId = await apiCall({
    token: stylistToken,
    contentType: configJSON.apiContentType,
    method: configJSON.getApiMethod,
    endPoint: `${configJSON.getPersonalUserApiEndPoint}?user_id=${stylist_id}`,
  });
}

getStylistInfoResponse = (resp: { data: { attributes: { [key: string]: string | null}}}) => {
  this.setState({
    isLoad: false,
    stylistFullName: resp.data.attributes.full_name || "",
    stylistProfile: resp.data.attributes.profile_picture || "",
    stylistId: resp.data.attributes.id || "",
  });
}

oncloseMsgAlert = () => {
  this.setState((prv)=>({...prv, isAlert: false}))
}


  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
