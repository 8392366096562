Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.sellerDetailsApiContentType = "application/json";
exports.sellersAPIEndPoint = "custom_form/seller_accounts";

exports.sellerDetailsAPIMethodPUT = "PUT";
exports.sellerDetailsAPIMethodPOST = "POST";
exports.sellerDetailsAPIMethodPATCH = "PATCH";
exports.getSellersAPIMethod = "GET";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customform";
exports.labelBodyText = "customform Body";
exports.errorMessageShopName = "Shop name is mandatory";
exports.errorMessageGstin = "GSTIN is mandatory";

exports.btnExampleTitle = "CLICK ME";
exports.postFavouriteApi = "bx_block_favourites/favourites";
exports.congrats = "Congratulations!";
exports.formSubmitted = "Your form is submitted";
exports.sellerDetails = "Seller Details";

exports.stores = "Stores";
exports.notStores = "No Store is added here";
exports.storeName = "Store Name";
exports.storeNamePlace = "Enter Store Name";
exports.storeDescription = "Store Description";
exports.storeDescriptionPlace = "Enter Store Description";
exports.next = "Next";
exports.back = "Back";
exports.create = "Create";
exports.storeUploadValidMsg = "Store image is required";
exports.storeNameValidMsg = "Store Name is required";
exports.storeNameExistMsg = "Store name already exists";
exports.storeDescriptionValidMsg = "Store Description is required";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpPatchMethod = "PATCH";
exports.httpDeleteMethod = "DELETE";
exports.validStoreNameEndPoint = "/accounts/bussiness/bussiness_name?store_name=";
exports.tokenInvalid = "Invalid token";

exports.addressReq = "*Address is Required";
exports.areaReq = "*Area is Required";
exports.blockReq = "*Block is Required";
exports.cityReq = "*City is Required";
exports.zipCodeNumErr = "*ZipCode must be a number";
exports.zipCodeReq = "*ZipCode is Required";
exports.paymentModeReq = "*Payment Mode is Required";
exports.instructionReq = "*Instructions for driver to reach store is Required";
exports.countryReq = "*Country is Required";
exports.phoneReq = "*Phone Number is Required";
exports.phoneNumErr = "*Phone Number must be a number";
exports.phoneMinErr = "*Phone number min length is 8";
exports.phoneMaxErr = "*Phone number max length is 10";
exports.afterReqMessage = ". Please enter the necessary information";

exports.countryCodeEndPoint = "accounts/country_code_and_flags";
exports.enterString = "Enter";
exports.optionalString = "Optional";
exports.selectString = "Select";
exports.address = "Address";
exports.area = "Area";
exports.block = "Block";
exports.mallName = "Mall Name";
exports.floor = "Floor";
exports.unitNumber = "Unit Number";
exports.city = "City";
exports.zipCode = "Zip Code";
exports.paymentMode = "Payment Mode";
exports.instruction = "Instructions for driver to reach store";
exports.phoneNumber = "Phone Number"
exports.instructionForDrivePlace = "Instructions for driver";
exports.alphaErr = "is accept alphanumeric value only";
exports.alphaRegx = /^[A-Za-z\s]+$/;
exports.numRegx = /^\d+$/;
exports.unitNumErr = "*Unit Number must be a number"

exports.createStoreEndPoint = "accounts/bussiness";
exports.createStoreApiContentType = "application/json";

exports.placeholderFrom = "From";
exports.placeholderTo = "To";

exports.sellerMyStoreEndPoint = "accounts/bussiness/seller_store";

exports.upload = "Upload";
exports.idUploadValidMsg = "ID is required";
exports.licenseUploadValidMsg = "License is required";

exports.sellerDocumentEndPoint = "accounts/document";

exports.storeStatusUpdateEndPoint = "accounts/bussiness/change_store_status?";
exports.storeUpdateTimingEndPoint = "account_block/accounts/bussiness";

exports.getCategoryEndPoint = "bx_block_catalogue/catalogues/get_category_by_store?";


exports.getFilterVariantInventoryEndPoint = "bx_block_catalogue/catalogues_variants/filter_variants?";
exports.getInventoryListEndPoint = "bx_block_catalogue/catalogues_variants/list_catalogue_variant?";
exports.getCataloguesByCategoryEndPoint = "bx_block_catalogue/catalogues/get_catalogue_by_store?per_page=10&";
exports.assignStoreEndPoint = "bx_block_catalogue/catalogues_variants/assign_batch_stores_catalouge_variant";

exports.getSellerDownloadTemplatesEndPoint = "accounts/download_file";
exports.getCataloguesVariantsOfStoreEndPoint = "bx_block_catalogue/catalogues_variants/search_catalogues";
exports.getVariantsOfProductEndPoint = "bx_block_catalogue/catalogues_variants/"
exports.putPairDataEndPoint = "bx_block_catalogue/catalogues_variants/"
exports.getSellerInventoryCountEndPoint = "account_block/accounts/bussiness/";
exports.getStylishInventoryCountEndPoint = "bx_block_catalogue/catalogues_variants/stylist_inventory_count"
exports.updateInventoryEndPoint = "bx_block_catalogue/catalogues_variants/batch_update_inventory";

exports.bulkUpdateInventoryEndPoint = "bx_block_catalogue/catalogues_variants/update_bulk_inventory_csv";
exports.bulkAddProductEndPoint = "bx_block_catalogue/catalogues/upload_bulk_catalogues";
exports.bulkEditProductEndPoint = "bx_block_catalogue/catalogues/edit_bulk_catalogues";
exports.bulkAssignStoreEndPoint = "bx_block_catalogue/catalogues_variants/assign_store_through_csv";
exports.bulkDeleteProductEndPoint = "bx_block_catalogue/catalogues_variants/delete_bulk_catalogues";

exports.getCategoriesEndPoint = "bx_block_categories/categories";
exports.getCataloguesVariantsSizesEndPoint = "bx_block_catalogue/catalogues_variants_sizes";
exports.getSubCategoriesEndPoint = "bx_block_categories/sub_categories?category_id=";
exports.latestRequestsGet = "bx_block_custom_form/hire_stylist_custom_forms"
exports.customForms = "bx_block_custom_form/hire_stylist_custom_forms";
exports.getStylishClientEndPoint = "account_block/hire_stylists/list_stylists_clients";
exports.getStoreBuyerEndPoint  = "accounts/bussiness/buyer_store";
exports.exampleApiContentType = "application/json";
exports.offerPercentage = "15% Off";
exports.getParticularStore = "accounts/bussiness";
// Customizable Area End
