import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
interface Service {
    id: string;
    type: string;
    attributes: {
        name: string;
        styling_per_week: number;
        discussion_time: string;
        voice_call_facility: boolean;
        video_call_facility: boolean;
        service_charges: number;
    };
}

interface INavigateTo {
    productId: string | undefined;
    props: unknown;
    screenName: string;
    raiseMessage?: Message;
}
type AlertType = 'success' | 'error' | 'warning' | 'info';
import React from "react";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "framework/src/Utilities";




export const configJSON = require("./config.js");
export interface IExplorePortfolioList {
    attributes: { image_url: string };
    id: string;
    type: string;
}
export interface ImagesEntity {
    description: string;
    portfolio_image_id: number;
    url: string;
    image_url: string
    image_id: number;
}
export interface IStylistList {
    type: string;
    id: string;
    attributes: StylistListAttributes;
}
export interface StylistListAttributes {
    country_code?: null;
    email: string;
    activated: boolean;
    first_name?: null;
    full_name: string;
    last_name?: null;
    full_phone_number: string;
    phone_number?: null;
    unique_auth_id: string;
    created_at: string;
    type: string;
    device_id?: null;
    currency: string;
    driver_status: string;
    updated_at: string;
    approve_status: string;
    seller_status: string;
    profile_picture: null | undefined | string;
    language: string;
    is_favorite: boolean;
    bio?: null;
}
interface CatalogueVariant {
    id: string;
    type: string;
    attributes: {
        id: number;
        catalogue_id: number;
        catalogue_variant_color_id: number;
        catalogue_variant_color: {
            id: number;
            name: string;
            created_at: string;
            updated_at: string;
        };
        catalogue_variant_size_id: number;
        catalogue_variant_size: {
            id: number;
            name: string;
            created_at: string;
            updated_at: string;
        };
        price: string;
        stock_qty: number;
        on_sale: null | boolean;
        sale_price: null | string;
        discount_price: null | string;
        length: null | number;
        breadth: null | number;
        height: null | number;
        created_at: string;
        updated_at: string;
        sku: string;
        deactivate: boolean;
        low_stock_threshold: number;
        is_listed: boolean;
        discounted_price: null | string;
        front_image: string;
        back_image: string;
        side_image: string;
        pair_it_with: [
            {
                id: string,
                type: string,
                attributes: {
                    id: number;
                    catalogue_id: number;
                    product_name: string;
                    product_description: string;
                    sku: string;
                    stock_qty: number;
                    low_stock_threshold: number;
                    is_listed: boolean;
                    price: string;
                    size: string;
                    colour: string;
                    gender: string;
                    front_image: string;
                    brand_name: string;
                }
            }
        ]
        dinar_price: number;
        currency_logo: string;
    };
    store_info?: {};
}
interface Catalogue {
    id: string;
    type: string;
    attributes: {
        name: string;
        brand: null | string;
        tags: { data?: [] };
        reviews?: [];
        sku: null | string;
        description: string;
        manufacture_date: null | string;
        length: null | number;
        breadth: null | number;
        height: null | number;
        stock_qty: null | number;
        availability: null | string;
        weight: null | number;
        price: null | string;
        recommended: null | boolean;
        on_sale: null | boolean;
        sale_price: null | string;
        discount: null | string;
        is_wishlist: boolean;
        product_number: null | string;
        primary_image: string;
        primary_price: string;
        gender: string;
        brand_name: string;
        material: string;
        fit: string;
        prodcut_care: string;
        list_the_product: string;
        fit_discription: string;
        category: {
            id: string;
            type: string;
            attributes: {
                id: number;
                name: string;
                status: string;
                created_at: string;
                updated_at: string;
                image: string;
            };
        };
        sub_category: {
            id: string;
            type: string;
            attributes: {
                id: number;
                name: string;
                created_at: string;
                updated_at: string;
                image: string;
            };
        };
        sub_sub_category: {
            id: string;
            type: string;
            attributes: {
                id: number;
                name: string;
                created_at: string;
                updated_at: string;
                image: string;
            };
        };
        service: null | string;
        average_rating: number;
        catalogue_variants: CatalogueVariant[];
        catalogue_variants_with_store: CatalogueVariant[];
        owner_first_name: string;
        owner_last_name: string;
        owner_full_name: string;
        owner_address: {
            address: string;
            area: string;
            block: string;
            mall_name: string;
            floor: string;
            unit_number: string;
            city: string;
            zipcode: string;
        };
    };
}

// Customizable Area End
export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    openStylistPortfolio: boolean;
    selected: number,
    token: string;
    openStylistProfile: boolean;
    visibleCount: number;
    stylistProfileData?: IStylistList | null;
    explorePortfolioList: ImagesEntity[];
    isAlertOrder: boolean,
    itemProfileId: number | string;
    already: string | null,
    alertType: AlertType,
    cardStylist: Catalogue[],
    productService: Service[],
    isChecked: boolean,
    baseUrl: string,
    isModalOpen: boolean,
    checkedError: string,
    // Customizable Area End
}
interface SS {
    navigation: any;
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SellerProfileController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    fetchProductSourcingApi: string = "";
    fetchPortfolioDataApi: string = "";
    favStylistProfileApi: string = "";
    fetchStylistProfileApi: string = "";
    getRequestedStylistProfileApi: string = "";
    unfavStylistProfileApi: string = "";
    getLickeStylistProfileApi: string = "";
    getDislikeStylistProfileApi: string = "";
    getCardStylistProfileApi: string = "";
    getServiceStylistProfileApi: string = "";
    getBuyPlanStylistProfileApi: string = "";
    getChargePostApi: string = "";
    getRecieveChargeApi: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.state = {
            // Customizable Area Start
            productService: [],
            already: "",
            cardStylist: [],
            itemProfileId: "",
            stylistProfileData: {
                id: "",
                type: "",
                attributes: {
                    activated: false,
                    country_code: null,
                    email: "",
                    first_name: null,
                    full_phone_number: "",
                    last_name: null,
                    full_name: "",
                    phone_number: null,
                    type: "",
                    created_at: "",
                    updated_at: "",
                    device_id: null,
                    unique_auth_id: "",
                    driver_status: "",
                    seller_status: "",
                    approve_status: "",
                    language: "",
                    currency: "",
                    bio: null,
                    profile_picture: "",
                    is_favorite: false,
                }
            },
            openStylistPortfolio: false,
            selected: 2,
            token: "",
            openStylistProfile: false,
            visibleCount: 7,
            explorePortfolioList: [],
            alertType: 'success' as AlertType,
            isAlertOrder: false,
            isChecked: false,
            baseUrl: '',
            isModalOpen: false,
            checkedError: ""
            // Customizable Area End
        };
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        // Customizable Area End
    }

    async componentDidMount(): Promise<void> {
        const userToken = await getStorageData('auth-token');
        const idData = this.props.navigation.getParam("navigationBarTitleText");
        this.setState({
            token: userToken,
            itemProfileId: idData
        });
        this.getPortfolioList()
        this.getRequestedStylistProfile()
        this.getCardStylistProfile()
        const basePath = window.location.origin
        this.setState({ baseUrl: basePath })
        this.savedCardUrl()
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (responseJson) {
                switch (apiRequestCallId) {
                    case this.fetchPortfolioDataApi:
                        this.setState({
                            explorePortfolioList: responseJson.data.map((portfolio: IExplorePortfolioList) => ({ image_url: portfolio?.attributes.image_url }))
                        });
                        break;
                    case this.getRequestedStylistProfileApi:
                        const selectedStylist = responseJson.data.find((stylist: { id: string | number }) => stylist.id === this.state.itemProfileId);
                        this.setState({ openStylistProfile: true, openStylistPortfolio: false, stylistProfileData: selectedStylist });
                        break;
                    case this.favStylistProfileApi:
                        this.setState({ isAlertOrder: true, alertType: 'success', already: "Profile added to favorite" })
                        break;
                    case this.unfavStylistProfileApi:
                        this.setState({ isAlertOrder: true, alertType: 'success', already: "Profile removed to favorite" })
                        break;
                    case this.getCardStylistProfileApi:
                        this.setState({ cardStylist: responseJson.data })
                        break;
                    case this.getLickeStylistProfileApi:
                        this.handleLickStylistProfileResponse(responseJson);
                        break;
                    case this.getDislikeStylistProfileApi:
                        this.handleDislikeStylistProfileResponse(responseJson)
                        break;
                    case this.getServiceStylistProfileApi:
                        this.setState({ productService: responseJson.data })
                        break;
                    case this.getBuyPlanStylistProfileApi:
                        this.handleBuyPlanStylistProfileResponse(responseJson)
                        break;
                    case this.getChargePostApi:
                        setStorageData("tapId", responseJson.id)
                        window.location.href = responseJson.transaction.url
                        break;
                    case this.getRecieveChargeApi:
                        this.handleApiResponse(responseJson)
                        break;
                    default:
                        break;
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start

    toggleFavorite = () => {
        if (this.state.stylistProfileData?.attributes.is_favorite) {
            this.unfavStylistProfile(this.state.itemProfileId);
            this.getRequestedStylistProfile()
        } else {
            this.favStylistProfile(this.state.itemProfileId);
            this.getRequestedStylistProfile()
        }
    };

    handleViewMore = () => {
        const { visibleCount } = this.state;
        const newVisibleCount = visibleCount + 24;
        this.setState({
            visibleCount: newVisibleCount,
        });
    };
    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        if (newValue === 1) {
            this.getProductSourcing()
        } else if (newValue === 2) {
            this.getStylistProfile()
        } else if (newValue === 0) {
            this.getServiceApi()
        }
        this.setState({ selected: newValue })
    }

    getPortfolioList = async () => {
        const headers = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": this.state.token
        };
        const requestMessagePortfolioList = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchPortfolioDataApi = requestMessagePortfolioList.messageId;
        requestMessagePortfolioList.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessagePortfolioList.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        requestMessagePortfolioList.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `portfolio/list_images?user_id=${this.state.itemProfileId}`);
        runEngine.sendMessage(requestMessagePortfolioList.id, requestMessagePortfolioList);

        return true;
    };

    getProductSourcing = async () => {
        const headers = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": this.state.token
        };
        const requestMessageProductSourcing = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchProductSourcingApi = requestMessageProductSourcing.messageId;
        requestMessageProductSourcing.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessageProductSourcing.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        requestMessageProductSourcing.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_catalogue/product_sourcing_requests`);
        runEngine.sendMessage(requestMessageProductSourcing.id, requestMessageProductSourcing);

        return true;
    };

    getStylistProfile = async () => {
        const headers = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": this.state.token
        };
        const requestMessageStylistProfile = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchStylistProfileApi = requestMessageStylistProfile.messageId;
        requestMessageStylistProfile.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessageStylistProfile.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        requestMessageStylistProfile.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/account_block/accounts/list_stylist?per_page=100`);
        runEngine.sendMessage(requestMessageStylistProfile.id, requestMessageStylistProfile);

        return true;
    };


    favStylistProfile = async (id: string | number | undefined) => {
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json"
        };
        const httpBody = { id: Number(id) };
        const requestMessageFavorite = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.favStylistProfileApi = requestMessageFavorite.messageId;
        requestMessageFavorite.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessageFavorite.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessageFavorite.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/buyer_favourites`);
        requestMessageFavorite.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        runEngine.sendMessage(requestMessageFavorite.id, requestMessageFavorite);

        return true;
    };

    unfavStylistProfile = async (id: string | number | undefined) => {
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json"
        };
        const requestMessageunFavorite = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.unfavStylistProfileApi = requestMessageunFavorite.messageId;
        requestMessageunFavorite.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessageunFavorite.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
        requestMessageunFavorite.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/account_block/buyer_favourites/${id}`);
        runEngine.sendMessage(requestMessageunFavorite.id, requestMessageunFavorite);

        return true;
    }

    getRequestedStylistProfile = async () => {
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json"
        };
        const requestMessageRequested = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getRequestedStylistProfileApi = requestMessageRequested.messageId;
        requestMessageRequested.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessageRequested.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        requestMessageRequested.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/accounts/list_stylist?per_page=100`);
        runEngine.sendMessage(requestMessageRequested.id, requestMessageRequested);
        return true;
    }
    oncloseAlertInProcess = () => {
        this.setState({ isAlertOrder: false });
    };

    getCardStylistProfile = async () => {
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json"
        };
        const requestMessageCardProfile = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCardStylistProfileApi = requestMessageCardProfile.messageId;
        requestMessageCardProfile.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessageCardProfile.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        requestMessageCardProfile.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues/list_stylist_catalogues?stylist_id=${this.state.itemProfileId}`);
        runEngine.sendMessage(requestMessageCardProfile.id, requestMessageCardProfile);
        return true;
    }
    is_wishlistData = (itemId: Catalogue, event: React.MouseEvent) => {
        event?.stopPropagation()
        if (itemId.attributes.is_wishlist) {
            this.wishlistDeleteGet(itemId.id);
        } else {
            this.wishlistLike(itemId.id);
        }
    }
    wishlistLike = (productId: number | string) => {
        const bodyLicke = { "data": { "favouriteable_id": Number(productId) } }
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json"
        };
        const requestMessageWishlistLike = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getLickeStylistProfileApi = requestMessageWishlistLike.messageId;
        requestMessageWishlistLike.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessageWishlistLike.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessageWishlistLike.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_favourites/favourites`);
        requestMessageWishlistLike.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(bodyLicke));
        runEngine.sendMessage(requestMessageWishlistLike.id, requestMessageWishlistLike);
        return true;
    }
    wishlistDeleteGet = (productId: number | string) => {
        const bodyDelete = { "data": { "favouriteable_id": Number(productId) } }
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json"
        };
        const requestMessageDislike = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getDislikeStylistProfileApi = requestMessageDislike.messageId;
        requestMessageDislike.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessageDislike.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
        requestMessageDislike.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_favourites/favourites/destroy_by_favouritable?favouriteable_id=${productId}`);
        requestMessageDislike.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(bodyDelete));
        runEngine.sendMessage(requestMessageDislike.id, requestMessageDislike);
        return true;
    }
    navigateTo = ({
        productId,
        props,
        screenName,
    }: INavigateTo) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationPropsMessage), props);
        message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
        productId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
        runEngine.sendMessage(message.id, message);
    }

    getServiceApi = () => {
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json"
        };
        const requestMessageService = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getServiceStylistProfileApi = requestMessageService.messageId;
        requestMessageService.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessageService.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        requestMessageService.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `accounts/service?stylist_id=${this.state.itemProfileId}`);
        runEngine.sendMessage(requestMessageService.id, requestMessageService);
        return true;
    }
    handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ isChecked: event.target.checked });
    };
    buybutton = (productId: number | string) => {
        const formData = new FormData();
        formData.append("stylist_id", String(this.state.itemProfileId));
        formData.append("policy", String(this.state.isChecked));
        formData.append("service_informations_id", String(productId));
        const headers = {
            "token": this.state.token,
        };
        const requestMessagebuybutton = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getBuyPlanStylistProfileApi = requestMessagebuybutton.messageId;
        requestMessagebuybutton.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessagebuybutton.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessagebuybutton.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/hire_stylists`);
        requestMessagebuybutton.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
        runEngine.sendMessage(requestMessagebuybutton.id, requestMessagebuybutton);
        return true;
    }

    hireCharge = (chargeId: number) => {
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json",
        };
        const bodyLicke = { "data": { "hire_request_id": chargeId, 'redirect_url': `${this.state.baseUrl}/HiredStylistProfile/${this.state.itemProfileId}` } }
        const requestMessageHireCharge = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getChargePostApi = requestMessageHireCharge.messageId;
        requestMessageHireCharge.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessageHireCharge.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessageHireCharge.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_tappaymentsintegration/tappayment/stylist_hire_charges`);
        requestMessageHireCharge.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(bodyLicke));
        runEngine.sendMessage(requestMessageHireCharge.id, requestMessageHireCharge);
        return true;
    }

    recieveCharge = (tapId: string | null) => {
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json",
        };
        const requestMessageRecieveCharge = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getRecieveChargeApi = requestMessageRecieveCharge.messageId;
        requestMessageRecieveCharge.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessageRecieveCharge.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        requestMessageRecieveCharge.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_tappaymentsintegration/tappayment/retrieve_hire_charge?charge_id=${tapId}`);
        runEngine.sendMessage(requestMessageRecieveCharge.id, requestMessageRecieveCharge);
        return true;
    }

    savedCardUrl = async () => {
        const params = new URLSearchParams(window.location.search);
        const tapId = params.get('tap_id');
        this.recieveCharge(tapId)
        await setStorageData("tapStylistId", tapId)
    }
    proceedToTrackOrderPage = () => {
        this.setState({ isModalOpen: false })
    }

    hiredStylistPage = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "HiredStylist");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }
    policiesStylistPage = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "TermsConditions");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }
    handleApiResponse = async (responseJson: { error: string; }) => {
        if (responseJson.error === "Payment not captured for hire charge.") {
            this.setState({ isModalOpen: false });
        } else {
            this.setState({ isModalOpen: true })
        }
    };

    handleLickStylistProfileResponse = (responseJson: { meta: { message: string; }; }) => {
        if (responseJson.meta.message === "Added to wishlist.") {
            this.setState({ already: "item added to wishlist", isAlertOrder: true }, () => { this.getCardStylistProfile(); });
        }
    };
    handleDislikeStylistProfileResponse = (responseJson: { message: string; }) => {
        if (responseJson.message === "Destroy successfully") {
            this.setState({ already: "item removed from wishlist", isAlertOrder: true }, () => this.getCardStylistProfile());
        }
    };

    handleBuyPlanStylistProfileResponse = (responseJson: { errors: string; data: { attributes: { id: number; }; }; }) => {
        if (responseJson.errors === "please sign the policy") {
            this.setState({ isAlertOrder: true, alertType: 'error', already: "*Please accept Terms and Conditions." });
        }
        this.hireCharge(responseJson.data.attributes.id);
    };
    // Customizable Area End
}