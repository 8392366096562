Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.mainTitle="Catalogues"
exports.tshirtTitle="No Products added here"
exports.getCatalogueList="bx_block_catalogue/catalogues?my_catalogues=true"
exports.getCatalogueDetail="bx_block_catalogue/catalogues"
exports.sub_sub_categories = "bx_block_categories/sub_sub_categories?sub_category_id"
exports.sub_categories = "bx_block_categories/sub_categories?category_id="
exports.varient_size = "bx_block_catalogue/catalogues_variants_sizes"
exports.varient_color ="bx_block_catalogue/catalogues_variants_colors"
exports.create_varient = "bx_block_catalogue/catalogues/list_variants"
exports.find_name = "bx_block_catalogue/catalogues/find_catalogue_by_name"
exports.sku_change = "bx_block_catalogue/catalogues_variants/check_sku_availability"
exports.categories = "bx_block_categories/categories"
exports.store_list = "accounts/bussiness/seller_store?approved=true"
exports.product_cataloge = "GET"
exports.imgUrl="image"
exports.dummyData={
  data:[{
  "id": "294",
  "type": "catalogue_listing",
  "attributes": {
      "name": "Brand 236",
      "description": "Lorem Ipsum",
      "primary_image": "https://fashionaggregator-326157-ruby.b326157.dev.eastus.az.svc/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbzhOIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--992d08f2b16cf4311a749958eed081604adfd124/2.jpg",
      "primary_price": "1000.0",
      "is_wishlist": false
  }
}]}
exports.getMyOfferAPIEndpoint = "bx_block_catalogue/product_sourcing_stylist_prices";
exports.getProductSourcingAPIEndpoint = "bx_block_catalogue/product_sourcing_requests";
exports.addProductSourcingBidAPIEndpoint = "bx_block_catalogue/product_sourcing_stylist_prices";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypePut = "PUT";
exports.apiMethodTypeDelete = "DELETE";
// Customizable Area End